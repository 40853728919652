import { Icon } from '@sitecore-ui/design-system';
import { mdiApplicationEdit, mdiFolderCheckOutline, mdiSitemap } from '@mdi/js';
import { FC } from 'react';
import { ItemViewModelPoc } from '../../models';
import { ItemType } from '../../hooks/useGetDetailedItem';

type ThumbnailProps = {
	item: ItemViewModelPoc;
	type: ItemType;
	siteAlternativeImageUrl: string;
};

export const Thumbnail: FC<ThumbnailProps> = ({ item }) => {
	let iconPath = mdiFolderCheckOutline;
	if (item.type === 'environment') {
		iconPath = mdiApplicationEdit;
	}
	if (item.type === 'site') {
		iconPath = mdiSitemap;
	}

	return <Icon data-testid="folderIcon" path={iconPath} w="220px" h="220px" />;
};

import { useAuthQuery } from '../apiUtils/queryHelper';
import { IGetTenantsResponse, IGetTenantsRequestPayload, IGetXmTenantRequestPayload, ITenant } from './tenantsModel';
import config from '../config/config';
import { UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';

export const useGetTenants = (
	{ organizationId, systemId, tenantName, pageNumber = 1, pageSize = 100 }: IGetTenantsRequestPayload,
	useQueryOptions?:
		| Omit<UseQueryOptions<AxiosResponse<IGetTenantsResponse>, unknown, AxiosResponse<IGetTenantsResponse>, any[]>, 'queryKey' | 'queryFn'>
		| undefined
) => {
	tenantName = tenantName || '';
	return useAuthQuery(
		['tenants', pageNumber, pageSize, organizationId, systemId, tenantName],
		async (axiosInstance) => {
			return axiosInstance.get<IGetTenantsResponse>(
				config.inventory.get_tenants.url(organizationId, systemId, tenantName, pageNumber, pageSize)
			);
		},
		{
			...useQueryOptions,
		},
		config.inventory.get_tenants.scope
	);
};

export const useGetTenant = (
	{ organizationId, tenantName }: IGetXmTenantRequestPayload,
	useQueryOptions?:
		| Omit<UseQueryOptions<AxiosResponse<IGetTenantsResponse>, unknown, AxiosResponse<IGetTenantsResponse>, any[]>, 'queryKey' | 'queryFn'>
		| undefined
) => {
	const { data, isLoading, isIdle } = useGetTenants(
		{
			organizationId: organizationId,
			tenantName: tenantName,
			systemId: config.inventory.xmSystemId,
			pageSize: 1,
		},
		useQueryOptions
	);

	return {
		data: data?.data.data[0],
		isLoading,
		isIdle,
	};
};

export const useGetTenantById = (
	tenantId: string,
	useQueryOptions?:
		| Omit<UseQueryOptions<AxiosResponse<IGetTenantsResponse>, unknown, AxiosResponse<ITenant>, any[]>, 'queryKey' | 'queryFn'>
		| undefined
) => {
	return useAuthQuery(
		['tenant', tenantId],
		async (axiosInstance) => {
			return axiosInstance.get<IGetTenantsResponse>(config.inventory.get_tenantById.url(tenantId));
		},
		{
			...useQueryOptions,
		},
		config.inventory.get_tenants.scope
	);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const domain: string = (window as any)?.env?.REACT_APP_AUTH0_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN;
const clientId: string = (window as any)?.env?.REACT_APP_AUTH0_CLIENT_ID || process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience: string = (window as any)?.env?.REACT_APP_AUTH0_AUDIENCE || process.env.REACT_APP_AUTH0_AUDIENCE;
export const identityBaseUrl: string = (window as any)?.env?.REACT_APP_IDENTITY_BASE_URL || process.env.REACT_APP_IDENTITY_BASE_URL;
export const portalBaseUrl: string = (window as any)?.env?.REACT_APP_PORTAL_BASE_URL || process.env.REACT_APP_PORTAL_BASE_URL;
export const inventoryBaseUrl: string = (window as any)?.env?.REACT_APP_INVENTORY_BASE_URL || process.env.REACT_APP_INVENTORY_BASE_URL;
export const deployBaseUrl: string = (window as any)?.env?.REACT_APP_DEPLOY_BASE_URL || process.env.REACT_APP_DEPLOY_BASE_URL;
export const inventoryXmCloudSystemId: string =
	(window as any)?.env?.REACT_APP_INVENTORY_XM_CLOUD_SYSTEMID || process.env.REACT_APP_INVENTORY_XM_CLOUD_SYSTEMID;
export const explorerBaseUrl: string = (window as any)?.env?.REACT_APP_EXPLORER_BASE_URL || process.env.REACT_APP_EXPLORER_BASE_URL;
export const pagesUri: string = (window as any)?.env?.REACT_APP_PAGES_BASE_URL || process.env.REACT_APP_PAGES_BASE_URL;
export const componentsBaseUrl: string = (window as any)?.env?.REACT_APP_COMPONENTS_BASE_URL || process.env.REACT_APP_COMPONENTS_BASE_URL;

export const environment: string = (window as any)?.env?.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT;
export const targetedEnvName: string = (window as any)?.env?.REACT_APP_TARGETED_ENVIRONMENT_NAME || process.env.REACT_APP_TARGETED_ENVIRONMENT_NAME;
export const xmCloudDeployBaseUrl: string = (window as any)?.env?.REACT_APP_XMCLOUD_DEPLOY_BASE_URL || process.env.REACT_APP_XMCLOUD_DEPLOY_BASE_URL;
export const minimalXmCloudVersion: string = (window as any)?.env?.REACT_APP_MINIMAL_XMCLOUD_VERSION || process.env.REACT_APP_MINIMAL_XMCLOUD_VERSION;
export const featureFlagsBaseUrl: string = (window as any)?.env?.REACT_APP_FEATURE_FLAGS_BASE_URL || process.env.REACT_APP_FEATURE_FLAGS_BASE_URL;
export const edgeGraphQlUrl: string = (window as any)?.env?.REACT_APP_EDGE_GRAPHQL_URL || process.env.REACT_APP_EDGE_GRAPHQL_URL;

export const manageRenderingHostUrl: string =
	(window as any)?.env?.REACT_APP_MANAGE_RENDERING_HOST_SERVICE_URL || process.env.REACT_APP_MANAGE_RENDERING_HOST_SERVICE_URL;
export const gitpodIntegrationUrl: string =
	(window as any)?.env?.REACT_APP_GITPOD_INTEGRATION_SERVICE_URL || process.env.REACT_APP_GITPOD_INTEGRATION_SERVICE_URL;
export const configurationServiceUrl: string =
	(window as any)?.env?.REACT_APP_HOST_CONFIGURATION_SERVICE_URL || process.env.REACT_APP_HOST_CONFIGURATION_SERVICE_URL;
export const launchpadUrl: string = (window as any)?.env?.REACT_APP_LAUNCHPAD_URL || process.env.REACT_APP_LAUNCHPAD_URL;

/**
 * Service to send apm events from this app to.
 * Setting this url also enables APM.
 */
export const apmServerUrl: string = (window as any)?.env?.REACT_APP_APM_SERVER_URL || process.env.REACT_APP_APM_SERVER_URL;

/**
 * The label this app appears in APM with, in the list of services.
 * Allowed characters: a-z, A-Z, 0-9, -, _, and space.
 * Optional. Defaults to 'XM Apps Dashboard UI'.
 */
export const apmServiceName: string =
	(window as any)?.env?.REACT_APP_APM_SERVICE_NAME || process.env.REACT_APP_APM_SERVICE_NAME || 'XM Apps Dashboard UI';

export const getAuthSettings = () => {
	return { domain, clientId, audience };
};

import { Button, EditIcon } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { DetailedItemBase } from 'features/millerColumns/hooks/useGetDetailedItem';
import { XMProjectsContext } from 'features/projects/projectsContext';
import { AssignRenderingHostButton } from 'features/renderingHosts/components/AssignRenderingHostButton';
import { useOpenSiteEditor } from 'features/sites/utils/useOpenSiteEditor';
import { FC, useCallback, useContext } from 'react';

export const ButtonGroup: FC<DetailedItemBase> = ({ currentItem, itemType }) => {
	const xMProjectContext = useContext(XMProjectsContext);
	const openSiteEditor = useOpenSiteEditor();

	const openPagesDependOnType = useCallback(() => {
		if (!currentItem) {
			return;
		}
		if (itemType === 'site') {
			openSiteEditor(xMProjectContext.model.environment.host, currentItem.name);
		}
	}, [currentItem, itemType, openSiteEditor]);

	if (itemType != 'site') {
		return <></>;
	}
	return (
		<>
			<Button variant="secondary" rightIcon={<EditIcon />} size="sm" onClick={openPagesDependOnType}>
				<T _str="Design" />
			</Button>
			<AssignRenderingHostButton buttonProps={{ id: 'AssignRenderingHostButton', variant: 'secondary', size: 'sm' }}>
				<T _str="Assign rendering host" />
			</AssignRenderingHostButton>
		</>
	);
};

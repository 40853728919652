import { AxiosResponse } from 'axios';
import { useAuthMutation, useAuthQuery } from 'features/common/apiUtils/queryHelper';
import config from 'features/common/config/config';
import { useCallback } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { CreateRenderingHostPayload } from '../data/createRenderingHostFormTypes';
import { RenderingHost } from '../millerColumns/models/millerItemModel';

type CreateRenderingHostOutput = (variables: CreateRenderingHostPayload, timeout: number) => Promise<string>;

export const useCreateRenderingHost = (): CreateRenderingHostOutput => {
	const createRenderingHostUrl = config.renderingHost.create_rendering_host.url();

	const { mutateAsync } = useAuthMutation((axiosInstance) => (payload: CreateRenderingHostPayload) => {
		return axiosInstance.post(createRenderingHostUrl, payload);
	});

	return useCallback(
		async (payload: CreateRenderingHostPayload) => {
			try {
				await mutateAsync(payload);

				return 'ok';
			} catch {
				//Do nothing
			}

			return '';
		},
		[mutateAsync]
	);
};

export type RefechCallbackRefactored = <TPageData>(
	options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<QueryObserverResult<AxiosResponse<RenderingHost[]>, unknown>>;

export const useGetRenderingHosts = (): { data: AxiosResponse<RenderingHost[]>; isLoading: boolean; refetch: RefechCallbackRefactored } => {
	const { data, isLoading, refetch } = useAuthQuery(['RenderingHosts', 'getAll'], async (axiosInstance) => {
		const result = axiosInstance.get<RenderingHost[]>(config.renderingHost.get_all_rendering_hosts.url());

		return result;
	});

	if (!data) {
		return { data: {} as AxiosResponse<RenderingHost[]>, isLoading, refetch };
	}

	return { data, isLoading, refetch };
};

export type RefechCallbackRemoveRenderingHost = <TPageData>(
	options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<QueryObserverResult<AxiosResponse<RenderingHost[]>, unknown>>;

export const useRemoveRenderingHosts = (id: string) => {
	const createRenderingHostUrl = config.renderingHost.remove_rendering_host.url(id);

	const { mutateAsync } = useAuthMutation((axiosInstance) => () => {
		return axiosInstance.delete(createRenderingHostUrl);
	});

	return useCallback(
		async function () {
			try {
				await mutateAsync();

				return 'ok';
			} catch {
				//Do nothing
			}

			return '';
		},
		[mutateAsync]
	);
};

import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useMemo } from 'react';
import { Loading } from '../components/Loading';
import { useResolveTenant } from './tenantResolver';
import { XMTenantContext, XMTenantModelWrapper } from './xmTenantContext';
import { useTenantAuth } from '../auth/TenantAuthProvider';

export const XMTenantProvider: React.FC = withAuthenticationRequired(
	({ children }) => {
		const { setTenantId, hasTenantAuth } = useTenantAuth();
		const { tenant, error, loading } = useResolveTenant();
		useEffect(() => {
			if (tenant) {
				setTenantId(tenant.id);
			}
		}, [setTenantId, tenant]);

		const xmTenant = useMemo<XMTenantModelWrapper>(() => {
			return tenant
				? {
						resolved: true,
						model: {
							id: tenant.id,
							name: tenant.name,
							url: tenant.annotations.URL && new URL(tenant.annotations.URL).origin,
							cdpId: tenant.annotations['XMCloud.CDPEmbeddedTenantID'],
						},
						error: error,
				  }
				: {
						resolved: false,
						error: error,
				  };
		}, [error, tenant]);

		if (error) {
			return <XMTenantContext.Provider value={xmTenant}>{children}</XMTenantContext.Provider>;
		}
		const waitingForTenantAuth = tenant && !hasTenantAuth;
		if (loading || waitingForTenantAuth) {
			return <Loading />;
		}

		return <XMTenantContext.Provider value={xmTenant}>{children}</XMTenantContext.Provider>;
	},
	{
		onRedirecting: () => <Loading />,
	}
);

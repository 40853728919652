import { Box, Flex } from '@sitecore-ui/design-system';
import { RefObject, useCallback, useRef, ReactNode, ReactElement, useImperativeHandle, MutableRefObject, useState } from 'react';
import classes from './MillerItem.module.css';
import { MillerItemProps, RenderingHost } from '../models/millerItemModel';
import { MillerColumn } from './MillerColumn';

export type MillerItemFactory<T> = (props: MillerItemProps<T>) => ReactNode;

export type MillerActiveIndex = {
	column: number;
	index: number;
};

export type MillerControl<T> = {
	selectItem: (item: T, columnIndex: number) => void;
};

export type MillerColumnsProps<T> = {
	column: T[];
	onClick?: (item: RenderingHost, itemIndex: number) => void;
	columnWidth?: number;
	visibleNum?: number;
	millerItemFactory: MillerItemFactory<T>;
	controlRef?: MutableRefObject<MillerControl<T> | undefined>;
};

export const MillerColumns = <T extends RenderingHost>({
	column,
	onClick,
	columnWidth = 300,
	millerItemFactory: millerItemFactory,
	controlRef,
}: MillerColumnsProps<T>): ReactElement => {
	const columnsViewRef = useRef<HTMLDivElement | null>();
	const [activeIds, setActiveIds] = useState<Array<string | undefined>>([undefined]);

	const updateActiveIds = useCallback(
		(columnIndex: number, itemId: string) => {
			const newActiveIds = [...activeIds.slice(0, columnIndex), itemId];
			setActiveIds(newActiveIds);
		},
		[activeIds]
	);

	const onItemSelected = useCallback(
		(item: RenderingHost, index: number) => {
			if (onClick) {
				updateActiveIds(0, item.id);
				onClick(item, index);
			}
		},
		[onClick, updateActiveIds]
	);

	useImperativeHandle(
		controlRef,
		() => ({
			selectItem: (item: T) => {
				const itemIndex = column.indexOf(item);
				onItemSelected(item, itemIndex);
			},
		}),
		[column, onItemSelected]
	);

	return (
		<Box data-testid="columns-box" className={classes.wrapper} ref={columnsViewRef as RefObject<HTMLDivElement>}>
			<Flex minW={columnWidth} h="full">
				{
					<MillerColumn
						w={columnWidth}
						onClick={(item: RenderingHost, itemIndex: number) => onItemSelected(item, itemIndex)}
						millerItemFactory={millerItemFactory}
						items={column}
						activeId={activeIds[0]}
					/>
				}
			</Flex>
		</Box>
	);
};

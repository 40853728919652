import { NavigationHorizontal, Text } from '@sitecore-ui/design-system';
import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { FC, Fragment } from 'react';
import config from '../../common/config/config';
import { useGetCurrentUser } from '../../common/services/user';
import { AppSwitcher } from './AppSwitcher';

export const TopBar: FC = () => {
	const navigate = useSaveNavigate();
	const { logout } = useAuthWithClaims();
	const { data } = useGetCurrentUser();

	const currentUser = data?.data;
	const name = currentUser?.givenName && currentUser.familyName ? `${currentUser.givenName} ${currentUser.familyName}` : '';

	return (
		<NavigationHorizontal
			icon={<AppSwitcher />}
			items={[
				(key) => (
					<Fragment key={key}>
						<Text
							key={key}
							css={{
								fontWeight: '600',
								color: '#737373',
								padding: '10px 12px 10px 0',
								lineHeight: '24px',
								fontSize: '14px',
							}}
						></Text>
					</Fragment>
				),
			]}
			image={{
				cursor: 'pointer',
				src: 'https://mss-p-006-delivery.stylelabs.cloud/api/public/content/539c69d50c3d4913a638624d418bcf61?v=043b01b9',
				onClick: () => navigate('/'),
			}}
			menu={{
				name,
				items: [
					{
						label: 'Manage account',
						onClick: () => window.open(config.portal.profile_manage),
					},
					{
						label: 'Logout',
						onClick: () =>
							logout({
								returnTo: window.location.origin,
							}),
					},
				],
			}}
			actions={
				[
					// {
					// 	active: false,
					// 	ariaLabel: 'Help',
					// 	icon: <QuestionOutlineIcon />,
					// 	onClick: () => {},
					// },
				]
			}
		/>
	);
};

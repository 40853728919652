import { createApolloClient, useCustomApolloClient } from 'features/common/apolloProvider/customApollo';
import { Site, SitesResponse } from 'features/millerColumns/models/site.model';
import { loader } from 'graphql.macro';
import { useCallback } from 'react';
import { SiteSelectItem } from '../data/createRenderingHostFormTypes';

type GetSitesCallback = {
	getSites: (host: string) => Promise<SiteSelectItem[]>;
};

export const getSItesQuery = loader('../queries/getSites.graphql');

export const useGetSitesCallback = (): GetSitesCallback => {
	const { asyncAuthLink, errorLink } = useCustomApolloClient();

	const getSites = useCallback(
		async (host: string): Promise<SiteSelectItem[]> => {
			if (host) {
				const client = createApolloClient(errorLink, asyncAuthLink, host);
				const sites = await client.query<SitesResponse>({ query: getSItesQuery });

				const mappedSelectItems = sites.data.sites.map(
					(site: Site) =>
						({
							value: site.name,
							label: site.name,
						} as SiteSelectItem)
				);

				return mappedSelectItems;
			}
			return [];
		},
		[asyncAuthLink, errorLink]
	);

	return { getSites };
};

import { useMemo } from 'react';
import { useGetProjects } from './projects';
import { IProject } from './projectsModel';

export type GetProjectsOrDefault = {
	projects?: IProject[];
	isLoading: boolean;
};

export const useGetProjectsOrDefault = (): GetProjectsOrDefault => {
	const { data, isLoading: namedSearchLoading } = useGetProjects();

	const response = useMemo(() => {
		if (namedSearchLoading) {
			return { isLoading: true };
		}
		if (data?.data) {
			const projects = data?.data.sort((a, b) => a.name.localeCompare(b.name));

			return { projects, isLoading: false };
		}

		return { projects: undefined, isLoading: false };
	}, [data?.data, namedSearchLoading]);

	return response;
};

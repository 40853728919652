import { Box, Flex } from '@sitecore-ui/design-system';
import { FC, useEffect, useRef } from 'react';
import { useGetMillerData } from '../hooks/useGetMillerData';
import { MillerColumns, MillerControl, MillerItem } from '.';
import { useGetDetailedItem } from '../hooks/useGetDetailedItem';
import { MillerItemType } from '../models/MillerColumnsTypes';
import { DetailsSection } from './detailsSection/DetailsSection';

export const MillerContainer: FC = () => {
	const { millerColumns, loadData, selectedProject, selectedSite } = useGetMillerData();
	const { onItemSelected, currentItem, itemType, loading } = useGetDetailedItem();
	const columnControlRef = useRef<MillerControl<MillerItemType>>();

	useEffect(() => {
		if (columnControlRef.current && selectedProject) {
			columnControlRef.current.selectItem(selectedProject, 0);
		}
	}, [selectedProject]);

	return (
		<Box w="full" h="full" bgColor="gray.50">
			<Flex h="full">
				<Box h="full" flexGrow={1} minW="0">
					<MillerColumns
						columns={millerColumns}
						onClick={(item, index) => {
							loadData(item, index);
							onItemSelected(item);
						}}
						millerItemFactory={(props) => <MillerItem {...props} />}
						controlRef={columnControlRef}
					/>
				</Box>
				<Box flexShrink={0}>
					<DetailsSection currentItem={currentItem} itemType={itemType} loading={loading} currentSite={selectedSite?.name} />
				</Box>
			</Flex>
		</Box>
	);
};

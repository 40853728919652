import { Tab, TabList } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FC } from 'react';

export const CreateRenderingHostFormTabList: FC = () => {
	return (
		<TabList style={{ borderColor: 'transparent' }}>
			<Tab fontWeight="semibold" paddingX={4} borderBottom="2px solid" borderColor="blackAlpha.200">
				<T _str="1: Setup rendering host" />
			</Tab>
			<Tab fontWeight="semibold" paddingX={4} borderBottom="2px solid" borderColor="blackAlpha.200">
				<T _str="2: Configure Project" />
			</Tab>
			<Tab fontWeight="semibold" paddingX={4} borderBottom="2px solid" borderColor="blackAlpha.200">
				<T _str="3: Configure Site" />
			</Tab>
		</TabList>
	);
};

import { AxiosResponse } from 'axios';
import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import config from 'features/common/config/config';
import { IEnvironment } from 'features/environments/environmentsModel';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from 'react-query';
import { EnvironmentSelectItem } from '../data/createRenderingHostFormTypes';

export const formatEnvironments = (environments: IEnvironment[]): EnvironmentSelectItem[] => {
	if (!environments) {
		return [];
	}

	const mappedEnvironments = environments.map(
		(environment: IEnvironment) =>
			({
				value: {
					environmentId: environment.id,
					environmentName: environment.name,
					host: environment.host,
					repositoryBranch: environment.repositoryBranch,
				},
				label: environment.name,
			} as EnvironmentSelectItem)
	);

	return mappedEnvironments;
};

export type RefechCallback = <TPageData>(
	options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<QueryObserverResult<AxiosResponse<IEnvironment[]>, unknown>>;

export const useEnvs = (projectId: string | undefined): { isLoading: boolean; refetch: RefechCallback } => {
	const { isLoading, refetch } = useAuthQuery(['environments', projectId], async (axiosInstance) => {
		if (projectId) {
			const result = axiosInstance.get<IEnvironment[]>(config.environments.get_environments.url(projectId));

			return result;
		}
		return {} as Promise<AxiosResponse<IEnvironment[]>>;
	});

	return { isLoading, refetch };
};

import { Box, Text } from '@sitecore-ui/design-system';
import { FC } from 'react';

export const FieldDetails: FC<{ name: string; value: string | undefined; alt?: string }> = ({ name, value, alt }) => {
	if ((value === undefined || value === '') && alt === undefined) {
		return <></>;
	}

	return (
		<Box textAlign="left">
			<Text>
				<Text as="i" fontWeight="bold">
					{name}
				</Text>
				<Text as="i"> </Text>
				<Text as="i" whiteSpace="pre-wrap">
					{value || alt}
				</Text>
			</Text>
		</Box>
	);
};

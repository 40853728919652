import { AxiosError } from 'axios';

export function isAxiosError(err: unknown): err is AxiosError<unknown> {
	return !!(err && (err as AxiosError).isAxiosError === true);
}

export function isError(err: unknown): err is Error {
	return !!(err && (err as Error).stack && (err as Error).message);
}

export interface Auth0Error {
	/**
	 * See more possible error responses in openid spec: https://openid.net/specs/openid-connect-core-1_0.html#AuthError.
	 */
	error: 'login_required' | 'consent_required' | 'unauthorized' | 'invalid_grant';
	error_description: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAuth0Error(err: any): err is Auth0Error {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
	return err && typeof err.error === 'string' && typeof err.error_description === 'string';
}

export interface IProblemDetails {
	type: string | null;
	title: string;
	status: number;
	detail: string;
}

/**
 * Check if an error response follows the standard "Problem Details for HTTP APIs".
 * @see https://datatracker.ietf.org/doc/html/rfc7807
 */
export function isProblemDetails(responseData: unknown): responseData is IProblemDetails {
	return !!(
		responseData &&
		(responseData as IProblemDetails).title &&
		(responseData as IProblemDetails).status &&
		(responseData as IProblemDetails).detail
	);
}

export interface IErrorMessageResponse {
	message: string;
}

export function isErrorMessageResponse(responseData: unknown): responseData is IErrorMessageResponse {
	return !!(responseData && (responseData as IErrorMessageResponse).message);
}

import { Flex, Spacer } from '@sitecore-ui/design-system';
import { FC } from 'react';
import { DetailedItemBase } from '../../hooks/useGetDetailedItem';
import { ButtonGroup } from './ButtonGroup';

export const ActionsPanel: FC<DetailedItemBase> = ({ currentItem }) => {
	const noActions = !currentItem;
	return (
		<Flex direction="row" height="12" paddingTop="5" paddingRight="7" gap="8">
			<Spacer />
			{noActions ? <></> : <ButtonGroup currentItem={currentItem} />}
		</Flex>
	);
};

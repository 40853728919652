import { Site } from 'features/millerColumns/models';
import { Environment } from 'features/renderingHosts/data/createRenderingHostTypes';
import { createContext } from 'react';
import { IProject } from './projectsModel';

export const XMProjectsContext = createContext<XMProjectsModelWrapper>({
	resolved: false,
	model: {
		project: {} as IProject,
		environment: {} as Environment,
		site: {} as Site,
	},
});

export type XMProjectsModelWrapper = {
	resolved: boolean;
	model: {
		project: IProject;
		environment: Environment;
		site: Site;
	};
};

import {
	Button,
	ButtonProps,
	Icon,
	IconButton,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Text,
} from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FC, useCallback } from 'react';
import { mdiArrowLeft } from '@mdi/js';
import { AssignRenderingHostToSiteForm } from '../forms/AssignRenderingHostToSiteForm';

type AssignRenderingHostButtonProps = {
	buttonProps: ButtonProps;
};

export const AssignRenderingHostButton: FC<AssignRenderingHostButtonProps> = ({ buttonProps, children }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const renderingHostSaved = useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<>
			<Button {...buttonProps} onClick={onOpen}>
				{children}
			</Button>

			<Modal isOpen={isOpen} onClose={onClose} size="full">
				<ModalOverlay>
					<ModalContent height="30rem" background="gray.50">
						<ModalHeader fontSize="3xl">
							<IconButton
								aria-label="close-assign-rendering-host-dialog"
								size="sm"
								mr={2}
								verticalAlign="bottom"
								icon={<Icon path={mdiArrowLeft}></Icon>}
								onClick={onClose}
							></IconButton>
							<Text as="span">
								<T _str="Assign rendering host" />
							</Text>
						</ModalHeader>
						<ModalCloseButton />
						<AssignRenderingHostToSiteForm closeModal={renderingHostSaved} />
					</ModalContent>
				</ModalOverlay>
			</Modal>
		</>
	);
};

import { GenericErrorTemplate, Link } from '@sitecore-ui/design-system';
import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { FC } from 'react';

export const NotFound: FC = () => {
	const navigate = useSaveNavigate();
	return (
		<GenericErrorTemplate
			title="Page not found"
			subtext="Error 404"
			text="The page you are looking for cannot be found"
			variant="not-found"
			shortcuts={
				<>
					<Link pb={4} onClick={() => navigate('/')}>
						Go to Homepage
					</Link>
					<Link pb={4} href="https://support.sitecore.com/kb">
						Visit Knowledge Base
					</Link>
				</>
			}
		/>
	);
};

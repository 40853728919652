import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCookie, useSessionStorage } from 'react-use';

export const tenantNameKey = (orgId: string | undefined) => (orgId ? `dashboard.${orgId}.tenantName` : 'dashboard.unknownOrg.tenantName');

export const useTenantParameter = (
	orgId: string | undefined
): [string | null, (newValue: string, options?: Cookies.CookieAttributes | undefined) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [tenantNameFromSession, updateTenantNameSession] = useSessionStorage<string | null>(tenantNameKey(orgId), null);
	const [tenantNameCookie, updateTenantNameCookie] = useCookie(tenantNameKey(orgId));
	const tenantNameParameter = useMemo(() => searchParams.get('tenantName'), [searchParams]);

	const tenantName = tenantNameParameter || tenantNameFromSession || tenantNameCookie;

	const storeTenantName = useCallback(() => {
		//searchParams.set('tenantName', newTenantName);
		//setSearchParams(searchParams);
		//updateTenantNameCookie(newTenantName);
		//updateTenantNameSession(newTenantName);
	}, [searchParams, setSearchParams, updateTenantNameCookie, updateTenantNameSession]);

	return [tenantName, storeTenantName];
};

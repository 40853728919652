import { HttpLink, ApolloClient, from, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useToast } from '@sitecore-ui/design-system';
import { useState } from 'react';
import { useAuthWithClaims } from '../auth/useAuthWithClaims';
import { useTranslate } from '../utils/translateUtils';

export function useCustomApolloClient() {
	const { getAccessTokenSilently } = useAuthWithClaims();
	const toast = useToast();
	const t = useTranslate();

	const [errorLink] = useState(
		onError(({ graphQLErrors, networkError, operation, forward }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message }) =>
					toast({
						status: 'error',
						description: t(message),
					})
				);
			if (networkError) {
				toast({
					title: t('Network error'),
					status: 'error',
					description: t(networkError.message),
				});
			}
			forward(operation);
		})
	);

	const [asyncAuthLink] = useState(
		setContext(() => {
			return new Promise((success, fail) => {
				getAccessTokenSilently()
					.then((token) => {
						success({
							headers: { authorization: `Bearer ${token}` },
						});
					})
					.catch((error) => fail(error));
			});
		})
	);

	return {
		errorLink,
		asyncAuthLink,
	};
}

export function createApolloClient(errorLink: ApolloLink, asyncAuthLink: ApolloLink, host: string) {
	const httpLink = new HttpLink({
		uri: host + '/sitecore/api/authoring/graphql/v1',
	});

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link: from([errorLink, asyncAuthLink, httpLink]),
	});

	return client;
}

export function createHorizonApolloClient(errorLink: ApolloLink, asyncAuthLink: ApolloLink, host: string) {
	const httpLink = new HttpLink({
		uri: host + '/sitecore/api/ssc/horizon/query/?sc_horizon=api',
	});

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link: from([errorLink, asyncAuthLink, httpLink]),
	});

	return client;
}

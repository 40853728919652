import { useAuthQuery } from '../common/apiUtils/queryHelper';
import { IGetProjectsResponse, IProject } from './projectsModel';
import config from '../common/config/config';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';

export const useGetProjects = (
	useQueryOptions?:
		| Omit<UseQueryOptions<AxiosResponse<IGetProjectsResponse>, unknown, AxiosResponse<IProject[]>, any[]>, 'queryKey' | 'queryFn'>
		| undefined
) => {
	return useAuthQuery(
		['projects'],
		async (axiosInstance) => {
			const result = axiosInstance.get<IGetProjectsResponse>(config.projects.get_projects.url());
			return result;
		},
		{
			...useQueryOptions,
		},
		config.projects.get_projects.scope
	);
};

export type RefechCallbackRefactored = <TPageData>(
	options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<QueryObserverResult<AxiosResponse<IProject>, unknown>>;

export const useGetProject = (projectId: string): { project: IProject; isLoading: boolean; refetch: RefechCallbackRefactored } => {
	const { data, isLoading, refetch } = useAuthQuery(['project', projectId], async (axiosInstance) => {
		if (projectId) {
			const result = axiosInstance.get<IProject>(config.projects.get_project.url(projectId));
			return result;
		}
		return {} as Promise<AxiosResponse<IProject>>;
	});

	const project = useMemo(() => {
		return data?.data ?? ({} as IProject);
	}, [data?.data]);

	return { project, isLoading, refetch };
};

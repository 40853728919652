import { mdiApplication, mdiFolderMultiple, mdiSatellite } from '@mdi/js';
import { Box, Button, ButtonProps, CardImageProps, Center, ChevronRightIcon, Text, TextProps, Icon } from '@sitecore-ui/design-system';
import { FC } from 'react';
import { MillerItemType } from '../models/MillerColumnsTypes';

export interface MillerItemProps<T> {
	data: T;
	isActiveColumn: boolean;
	isActiveItem: boolean;
	onClick: () => void;
}

const iconProps: TextProps = {
	display: 'inline-flex',
	fontSize: 22,
	height: 22,
	lineHeight: 22,
};

const buttonProps: ButtonProps = {
	variant: 'minimal',
	borderRadius: 10,
	justifyContent: 'flex-start',
	height: 'auto',
	w: '100%',
	minHeight: '60px',
	textAlign: 'left',
	p: 2,
};

const thumbnailurlProps: CardImageProps = {
	borderRadius: '4',
	w: '44px',
	h: '44px',
};

export const MillerItem: FC<MillerItemProps<MillerItemType>> = ({ data, isActiveColumn, isActiveItem, onClick }) => {
	let bgColor = 'white';

	if (isActiveColumn && isActiveItem) {
		bgColor = 'primary.100';
	} else if (isActiveItem) {
		bgColor = 'gray.100';
	}

	return (
		<Button data-testid="item-button" data-id={data.id} bgColor={bgColor} onClick={onClick} {...buttonProps}>
			<Box w="44px" d="flex" mr="15px" data-testid="item-thumbnail">
				<Center {...thumbnailurlProps} bgColor="gray.100">
					<Text {...iconProps}>
						{data.type == 'project' && <Icon data-testid="pageIcon" path={mdiFolderMultiple} w="24px" h="24px" />}
						{data.type == 'environment' && <Icon data-testid="pageIcon" path={mdiApplication} w="24px" h="24px" />}
						{data.type == 'site' && <Icon data-testid="pageIcon" path={mdiSatellite} w="24px" h="24px" />}
					</Text>
				</Center>
			</Box>
			<Box flex="1" alignItems="start" isTruncated>
				<Text isTruncated>{data.name}</Text>
			</Box>
			<Box w="30px" color="gray">
				{data.hasChildren && <ChevronRightIcon fontSize="25" />}
			</Box>
		</Button>
	);
};

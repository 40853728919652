import { AxiosResponse } from 'axios';
import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import config from 'features/common/config/config';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { RepositoryUrlPayload } from '../data/renderingHostConfigurationTypes';

export type RefechCallback = <TPageData>(
	options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<QueryObserverResult<AxiosResponse<RepositoryUrlPayload[]>, unknown>>;

export const useGetRepositoryUrl = (integrationId: string | undefined): { isLoading: boolean; refetch: RefechCallback } => {
	////https://xmcloud.sitecorecloud.io/api/sourcecontrol/v1/repository?integrationId=%7B%7Bintegrationid%7D%7D

	const { isLoading, refetch } = useAuthQuery(['integrationId', integrationId], async (axiosInstance) => {
		if (integrationId) {
			const result = await axiosInstance.get<RepositoryUrlPayload[]>(config.repositoryUrl.get_repositoryUrl.url(integrationId));

			return result;
		}
		return {} as Promise<AxiosResponse<RepositoryUrlPayload[]>>;
	});

	return { isLoading, refetch };
};

import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { getAuthSettings } from '../config/envConfig';
import { fetchLastSuccessfulLoginOrganizationId, OrganizationProvider } from './OrganizationStorage';
import { TenantAuthProvider } from './TenantAuthProvider';
import { useNavigate } from 'react-router-dom';

const organizationIdFromLastSuccessfulLogin = fetchLastSuccessfulLoginOrganizationId();

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
	const { domain, clientId, audience } = getAuthSettings();

	const navigate = useNavigate();

	const onRedirectCallback = (appState?: AppState) => {
		navigate(appState?.returnTo || window.location.pathname);
	};

	if (!domain || !clientId || !audience) {
		throw new Error('Missing auth provider properties');
	}

	return (
		<Auth0Provider
			audience={audience}
			domain={domain}
			clientId={clientId}
			redirectUri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
			useRefreshTokens={true}
			organization={organizationIdFromLastSuccessfulLogin}
		>
			<TenantAuthProvider>
				<OrganizationProvider>{children}</OrganizationProvider>
			</TenantAuthProvider>
		</Auth0Provider>
	);
};

import { FC } from 'react';
import { Box } from '@sitecore-ui/design-system';
import { DashboardLoadingScreen } from './DashboardLoadingScreen';

export const Loading: FC = () => {
	return (
		<Box h="100vh">
			<DashboardLoadingScreen text="Loading..." />
		</Box>
	);
};

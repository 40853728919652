import { Interpolation } from '@emotion/react';
import { Box, BoxProps } from '@sitecore-ui/design-system';
import React, { ReactElement } from 'react';
import { MillerItemBase } from '../models';
import { MillerItemFactory } from './MillerColumns';

export type MillerColumnProps<T> = {
	items: T[];
	w?: number | string;
	isActiveColumn: boolean;
	activeId: string | undefined;
	onClick: (item: T, itemIndex: number) => void;
	millerItemFactory: MillerItemFactory<T>;
	css?: Interpolation<unknown>;
};

const boxProps: BoxProps = {
	d: 'flex',
	h: '100%',
	p: 2,
	flexDirection: 'column',
	overflowY: 'auto',
	flex: '0 0 auto',
};

export const MillerColumn = <T extends MillerItemBase>({
	isActiveColumn,
	activeId,
	items,
	w,
	onClick,
	millerItemFactory,
	css,
}: MillerColumnProps<T>): ReactElement => {
	return (
		<Box {...boxProps} w={w} data-testid="column-box" css={css} gap="1">
			{items.map((item, index) => (
				<React.Fragment key={index}>
					{millerItemFactory({
						data: item,
						isActiveColumn,
						isActiveItem: item.id === activeId,
						onClick: () => onClick(item, index),
					})}
				</React.Fragment>
			))}
		</Box>
	);
};

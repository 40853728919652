import { FC, useEffect } from 'react';
import { useAuthWithClaims } from './useAuthWithClaims';

const orgIdStorageKey = 'xmapps-dashboard:org_id';

export function fetchLastSuccessfulLoginOrganizationId(): string | undefined {
	return sessionStorage.getItem(orgIdStorageKey) || undefined;
}

export function storeLastSuccessfulLoginOrganizationId(organizationId?: string) {
	if (organizationId) {
		sessionStorage.setItem(orgIdStorageKey, organizationId);
	} else {
		sessionStorage.removeItem(orgIdStorageKey);
	}
}

export const OrganizationProvider: FC = ({ children }) => {
	const { user } = useAuthWithClaims();
	const organizationId = user?.['https://auth.sitecorecloud.io/claims/org_id'];

	useEffect(() => {
		storeLastSuccessfulLoginOrganizationId(organizationId);
	}, [organizationId]);
	return <>{children}</>;
};

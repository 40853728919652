import { SiteInfo } from 'features/sites/data/createSiteTypes';
import { SitesResponse, Site, ItemViewModelPoc } from '../models';
import { IProject } from 'features/projects/projectsModel';
import { IEnvironment } from 'features/environments/environmentsModel';
import { MillerItemType } from '../models/MillerColumnsTypes';

export const formatSites = (sites: SiteInfo[]): MillerItemType[] => {
	return sites.map(
		(site: SiteInfo) =>
			({
				id: site.id,
				name: site.name,
				hasChildren: false,
				type: 'site',
				thumbnailUrl: site.imageSrc,
			} as MillerItemType)
	);
};

export const formatAllSites = (sites: SitesResponse): MillerItemType[] => {
	return sites.sites.map(
		(site: Site) =>
			({
				id: site.name,
				name: site.name,
				hasChildren: false,
				type: 'site',
			} as MillerItemType)
	);
};

export const formatProjects = (projects: IProject[]): MillerItemType[] => {
	return projects.map(
		(project: IProject) =>
			({
				id: project.id,
				name: project.name,
				hasChildren: true,
				projectDetails: {
					sourceControlIntegrationName: project.sourceControlIntegrationName,
					sourceControlIntegrationId: project.sourceControlIntegrationId,
					repository: project.repository,
				},
				type: 'project',
			} as MillerItemType)
	);
};

export const formatEnvironments = (environments: IEnvironment[] | undefined): MillerItemType[] => {
	if (!environments || environments.length === 0) {
		return [];
	}

	return environments.map(
		(environment: IEnvironment) =>
			({
				id: environment.id,
				name: environment.name,
				hasChildren: true,
				envDetails: {
					host: environment.host,
					hostLink: 'https://' + environment.host,
					platformTenantName: environment.platformTenantName,
					repositoryBranch: environment.repositoryBranch,
				},
				type: 'environment',
			} as MillerItemType)
	);
};

export const formatDetailedItem = (detailedItem: MillerItemType): ItemViewModelPoc => {
	return {
		itemId: detailedItem.id,
		name: detailedItem.name,
		type: detailedItem.type,
		hostUrl: detailedItem.envDetails?.hostLink,
		repositoryBranch: detailedItem.envDetails?.repositoryBranch,
		sourceControlIntegrationName: detailedItem.projectDetails?.sourceControlIntegrationName,
		repository: detailedItem.projectDetails?.repository,
		thumbnailUrl: detailedItem.thumbnailUrl,
	};
};

import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { defineCustomElements, applyPolyfills } from '@sitecore-ui/web-components/loader';
import { setupApm } from 'features/common/apm/setupApm';

void applyPolyfills().then(() => {
	void defineCustomElements();
});

setupApm();

const renderApp = () => {
	render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		document.getElementById('root')
	);
};

if (process.env.REACT_APP_MOCK === 'enabled') {
	void import('./mocks/browser').then((value) => {
		void value.worker.start().then(() => renderApp());
	});
} else {
	renderApp();
}

export interface Deferrable<T> {
	promise: Promise<T>;
	resolve: (value: T) => void;
	reject: (reason?: any) => void;
}

/**
 * Generally considered an anti-pattern and should not be used in production code due to bad error handling.
 * See [The Deferred anti-pattern](https://github.com/petkaantonov/bluebird/wiki/Promise-anti-patterns?utm_campaign=Mastering%20JS%20Weekly&utm_medium=email&utm_source=Revue%20newsletter#the-deferred-anti-pattern).
 *
 * Introduced for easier control flow in UI tests.
 */
export function defer<T>(): Deferrable<T> {
	const deferred = {} as Deferrable<T>;

	deferred.promise = new Promise<T>((resolve, reject) => {
		deferred.resolve = resolve;
		deferred.reject = reject;
	});

	return deferred;
}

/**
 * Retry calling an async function until it succeeds.
 *
 * @param callback Async function to try again and again and again.. n times.
 * @param times Number of times callback should be called. Should be 1 or higher.
 * @example
 * retry(startMonitoringConnection, 5).then(() => ...);
 */
export function retry<T>(callback: () => Promise<T>, times: number, err?: any): Promise<T> {
	if (!times) {
		return Promise.reject(err);
	}

	return callback().catch((error) => {
		return retry(callback, times - 1, error);
	});
}

export function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

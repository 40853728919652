import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { useToast } from '@sitecore-ui/design-system';
import { useCallback, useMemo } from 'react';
import { isAuth0Error } from '../utils/errorUtils';
import { useTranslate } from '../utils/translateUtils';
import { SitecoreUser } from './SitecoreUser';
import { useTenantAuth } from './TenantAuthProvider';

export interface SitecoreAuthClaimOptions {
	tenant_id?: string;
	organization?: string;
}

/**
 * Ensures that getAccessTokenSilently always includes organization and tenant_id parameters (if user contains one or both of those claims).
 */
export function useAuthWithClaims() {
	const auth0 = useAuth0<SitecoreUser>();
	const toast = useToast();
	const t = useTranslate();
	const { tenantId } = useTenantAuth();
	const getAccessTokenSilently = useCallback(
		(options?: GetTokenSilentlyOptions & SitecoreAuthClaimOptions): Promise<string> => {
			return auth0
				.getAccessTokenSilently({
					tenant_id: tenantId,
					organization: auth0.user?.['https://auth.sitecorecloud.io/claims/org_id'],
					...options,
				})
				.catch((error) => {
					// Likely something wrong with org or tenant id.
					// Either organization/tenant doesn't exist, or tenant doesn't belong to specified org.
					// Can also be that user doesn't have access to org/tenant.
					// There is also a few other reasons this can trigger like audience and scope.
					return new Promise(() => {
						toast({
							status: 'error',
							title: t('Failed to authorize'),
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
							description: isAuth0Error(error) ? error.error_description : error?.message ?? error?.toString(),
							button: {
								children: t('Login again'),
								onClick: () => {
									void auth0.loginWithRedirect();
								},
							},
						});
					});
				});
		},
		[auth0, t, tenantId]
	);

	const authWithClaims = useMemo(
		() => ({
			...auth0,
			getAccessTokenSilently: getAccessTokenSilently,
		}),
		[auth0, getAccessTokenSilently]
	);

	return authWithClaims;
}

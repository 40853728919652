import { Box, Flex, Layout, LayoutHeader, LayoutMain } from '@sitecore-ui/design-system';
import { TopBar } from '../../topbar/components/TopBar';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from 'features/common/components/Loading';
import { Route, Routes } from 'react-router-dom';
import { MainTabs } from './MainTabs';
import { NotFound } from './NotFound';
import { ProjectsLayout } from 'features/projects/components/ProjectsLayout';
import { RenderingHostsLayout } from 'features/renderingHosts/layout/RenderingHostsLayout';

export const MainContainer = withAuthenticationRequired(
	() => {
		return (
			<Layout>
				<LayoutHeader>
					<TopBar />
				</LayoutHeader>
				<LayoutMain disablePadding backgroundColor="gray.50" overflow="hidden" height="100%">
					<Flex flexDirection="column" height="100%" flexGrow={1} backgroundColor="gray.200">
						<Routes>
							<Route path="/" element={<MainTabs />} />
							<Route path="/renderingHosts" element={<MainTabs />} />
							<Route path="*" element={<></>} />
						</Routes>
						<Box flexGrow={1} height="100%" overflow="auto" backgroundColor="gray.50">
							<Routes>
								<Route path="/" element={<ProjectsLayout />} />
								<Route path="/renderingHosts" element={<RenderingHostsLayout />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
						</Box>
					</Flex>
				</LayoutMain>
			</Layout>
		);
	},
	{
		onRedirecting: () => <Loading />,
	}
);

import { IProject } from 'features/projects/projectsModel';
import { Environment } from './createRenderingHostTypes';

export type CreateRenderingHostFormData = {
	renderingHostId: string;
	renderingHostName: string;
	useGitpodRenderingHost: boolean;
	renderingHostUrl?: string;
	siteName: string;
	projectId: string;
	projectName?: string;
	environmentId: string;
	environmentName?: string;
	platformTenantName?: string;
	host: string;
	repository?: string;
	sourceControlIntegrationId?: string;
	sourceControlIntegrationName?: string;
	repositoryUrl?: string;
};

export type CreateRenderingHostFormDataProps = {
	formData: CreateRenderingHostFormData;
	gitpodIntegration: {
		ideId: string;
		userLoggedIn: boolean;
		isWorkspaceCreated: boolean;
		workspaceRegistrationLink: string;
		workspaceUrl?: string;
	};
	hasErrors: boolean;
};

export function makeid(length: number) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function getInitialRenderingHostFormDefaultValues(): CreateRenderingHostFormDataProps {
	const values = {
		formData: {
			renderingHostId: '',
			renderingHostName: '',
			useGitpodRenderingHost: false,
			renderingHostUrl: '',
			siteName: '',
			projectId: '',
			projectName: '',
			environmentId: '',
			environmentName: '',
			platformTenantName: '',
			host: '',
			repository: '',
			sourceControlIntegrationId: '',
			sourceControlIntegrationName: '',
			repositoryUrl: '',
		},
		gitpodIntegration: {
			ideId: makeid(8),
			userLoggedIn: false,
			isWorkspaceCreated: false,
			workspaceRegistrationLink: '',
			workspaceUrl: '',
		},
		hasErrors: false,
	} as CreateRenderingHostFormDataProps;

	return values;
}

export type CreateRenderingHostPayload = {
	id: string;
	name: string;
	renderingHostUrl: string;
	repositoryUrl: string;
	workspaceId: string;
	workspaceUrl: string;
	siteName: string;
	environmentName: string;
	environmentId: string;
	host: string;
	renderingHostHostingMethod: string;
};

export type DeployRenderingHostSelectItem = {
	value: boolean;
	label: string;
};

export type ProjectSelectItem = {
	value: IProject;
	label: string;
};

export type EnvironmentSelectItem = {
	value: Environment;
	label: string;
};

export type SiteSelectItem = {
	value: string;
	label: string;
};

import { Button, Flex, H4, Spacer } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { useRemoveRenderingHosts } from 'features/renderingHosts/services/renderingHostManagmentService';
import { FC, useState } from 'react';
import { DetailedItemBase } from '../../hooks/useGetDetailedItem';
import { ActionsPanel } from './ActionsPanel';
import { FieldDetails } from './FieldDetails';

export const DetailsSection: FC<DetailedItemBase> = ({ currentItem }) => {
	const removeRenderingHost = useRemoveRenderingHosts(currentItem?.id || '');
	const [isRemoving, setIsRemoving] = useState(false);
	if (!currentItem) {
		return <></>;
	}

	const handleRenderingHostRemoval = () => {
		setIsRemoving(true);
		void removeRenderingHost();
		setIsRemoving(false);
	};

	const renderingHostStatus = (): boolean => {
		try {
			//Implement rendering host ping
		} catch {
			return false;
		}
		return true;
	};

	return (
		<Flex direction="column" width="580px" borderLeft="1px" borderColor="gray.200" data-testid="detailsSection" h="auto">
			<ActionsPanel currentItem={currentItem} />
			<Flex marginLeft="8" paddingTop={10} direction="column" gap="3">
				<H4 marginTop={2}>{currentItem.name}</H4>
				<FieldDetails name="Rendering host status:" value={renderingHostStatus() ? 'available' : 'unavailable'} />
				<FieldDetails name="Rendering host Url:" value={currentItem.renderingHostUrl} />
				<FieldDetails name="Site:" value={currentItem.siteName} />
				<FieldDetails name="Sitecore Host:" value={currentItem.host} />
				<FieldDetails name="Rendering host repository Url:" value={currentItem.repositoryUrl} />
				<FieldDetails name="Gitpod workspace url:" value={currentItem.workspaceUrl} />
				<FieldDetails name="Environment name:" value={currentItem.environmentName} />
			</Flex>
			<Flex direction="row" height="10" paddingTop="5" paddingRight="7" gap="10">
				<Spacer />
				<Button variant="secondary" size="sm" onClick={() => void handleRenderingHostRemoval()} isLoading={isRemoving}>
					<T _str="Remove" />
				</Button>
			</Flex>
		</Flex>
	);
};

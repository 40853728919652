/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { sitecoreTheme } from '@sitecore-ui/design-system';

export const sitecoreSizes = {
	size3: sitecoreTheme.sizes[3] as string,
	size4: sitecoreTheme.sizes[4] as string,
	size5: sitecoreTheme.sizes[5] as string,
	size6: sitecoreTheme.sizes[6] as string,
	size7: sitecoreTheme.sizes[7] as string,
	size9: sitecoreTheme.sizes[9] as string,
	size10: sitecoreTheme.sizes[10] as string,
	size16: sitecoreTheme.sizes[16] as string,
	size32: sitecoreTheme.sizes[32] as string,
	size34: sitecoreTheme.sizes[34] as string,
	size48: sitecoreTheme.sizes[48] as string,
	sizeMd: sitecoreTheme.sizes.md as string,
	size72: sitecoreTheme.sizes[72] as string,
	size80: sitecoreTheme.sizes[80] as string,
	size44: sitecoreTheme.sizes[44] as string,
	size56: sitecoreTheme.sizes[56] as string,
};

export const sitecoreColors = {
	gray400: sitecoreTheme.colors.gray['400'] as string,
	gray100: sitecoreTheme.colors.gray['100'] as string,
	gray50: sitecoreTheme.colors.gray['50'] as string,
	gray200: sitecoreTheme.colors.gray['200'] as string,
	primary600: sitecoreTheme.colors.primary['600'] as string,
	primary500: sitecoreTheme.colors.primary['500'] as string,
	primary100: sitecoreTheme.colors.primary['100'] as string,
	pink100: sitecoreTheme.colors.pink['100'] as string,
	blackAlpha200: sitecoreTheme.colors.blackAlpha['200'] as string,
	baseShadow: sitecoreTheme.shadows.base as string,
};

import {
	identityBaseUrl,
	portalBaseUrl,
	inventoryBaseUrl,
	inventoryXmCloudSystemId,
	explorerBaseUrl,
	componentsBaseUrl,
	featureFlagsBaseUrl,
	environment,
	deployBaseUrl,
	manageRenderingHostUrl,
	gitpodIntegrationUrl,
	configurationServiceUrl,
} from './envConfig';

const identityUrl = `${identityBaseUrl}/api/identity/v1`;
const inventoryUrl = `${inventoryBaseUrl}/api/inventory/v1`;
const explorerUrl = explorerBaseUrl;
const featureFlagsUrl = featureFlagsBaseUrl + '/api/featureflags/v1';

const projectsUrl = `${deployBaseUrl}/api/projects/v1`;
const sourceControlUrl = `${deployBaseUrl}/api/sourcecontrol/v1/repository`;
const renderingHostServiceUrl = `${manageRenderingHostUrl}/api/renderinghost`;
const gitpodIntegrationServiceUrl = `${gitpodIntegrationUrl}/api/v1/workspace`;
const hostConfigurationServiceUrl = `${configurationServiceUrl}/api`;

const identity = {
	get_user: {
		scope: 'identity.user:read',
		url: `${identityUrl}/user`,
	},
};

const portal = {
	profile_manage: `${portalBaseUrl}/profile/manage`,
};

const renderingHost = {
	create_rendering_host: {
		url: () => {
			return `${renderingHostServiceUrl}/createrenderinghost`;
		},
	},
	remove_rendering_host: {
		url: (id: string) => {
			return `${renderingHostServiceUrl}/removerenderinghost/${id}`;
		},
	},
	get_all_rendering_hosts: {
		url: () => {
			return `${renderingHostServiceUrl}/GetAllRenderingHosts`;
		},
	},
	get_gitpod_all_workspaces: {
		url: () => {
			return `${gitpodIntegrationServiceUrl}`;
		},
	},
	get_gitpod_workspace_by_id: {
		url: (ideId: string) => {
			return `${gitpodIntegrationServiceUrl}/${ideId}`;
		},
	},
};

const projects = {
	get_projects: {
		scope: 'xmclouddeploy.projects:read',
		url: () => {
			return `${projectsUrl}`;
		},
	},
	get_project: {
		scope: 'xmclouddeploy.projects:read',
		url: (projectId: string) => {
			return `${projectsUrl}/${projectId}`;
		},
	},
};

const environments = {
	get_environments: {
		scope: 'xmclouddeploy.environments:read',
		url: (projectId: string) => {
			return `${projectsUrl}/${projectId}/environments`;
		},
	},
};

const hostConfigurationUrl = {
	get_hostConfigurationUrl: {
		url: (host: string) => {
			return `${hostConfigurationServiceUrl}/configuration?host=${host}`;
		},
	},
};

const repositoryUrl = {
	get_repositoryUrl: {
		scope: 'xmclouddeploy.sourcecontrol:manage',
		url: (integrationId: string) => {
			return `${sourceControlUrl}/?integrationId=${integrationId}`;
		},
	},
};

const inventory = {
	xmSystemId: inventoryXmCloudSystemId,
	get_tenants: {
		scope: 'inventory.tenants:read',
		url: (organizationId: string, systemId: string, name: string | undefined, pagenumber = 1, pagesize = 20) => {
			return `${inventoryUrl}/tenants?pagenumber=${pagenumber}&pagesize=${pagesize}&state=Active&organizationId=${organizationId}&systemId=${systemId}&name=${
				name || ''
			}`;
		},
		xmCloudSystemId: inventoryXmCloudSystemId,
	},
	get_tenantById: {
		scope: 'inventory.tenants:read',
		url: (tenantId: string) => {
			return `${inventoryUrl}/tenants/${tenantId}`;
		},
	},
};

const cdp = {
	create_pos: {
		url: (cdpUrl: string) => `${cdpUrl}/v2/tenants/points-of-sale`,
	},
	get_all_pos: {
		url: (cdpUrl: string) => `${cdpUrl}/v2/tenants/points-of-sale`,
	},
};

export const explorer = {
	getUrl: (): string => {
		return explorerUrl;
	},
};

export const components = {
	getUrl: (): string => {
		return componentsBaseUrl;
	},
};

const featureFlags = {
	scope: 'featureflags',
	url: `${featureFlagsUrl}/flags?key=dashboard&labelfilter=${environment}`,
};

export default {
	identity,
	inventory,
	portal,
	projects,
	environments,
	repositoryUrl,
	explorer,
	cdp,
	featureFlags,
	renderingHost,
	hostConfigurationUrl,
};

import { Container, FormControl, FormErrorMessage, FormLabel, Select, Stack, WarningTwoIcon } from '@sitecore-ui/design-system';
import { SingleValue } from '@sitecore-ui/design-system/lib/components/Select';
import { T } from '@transifex/react';
import { FormikProps } from 'formik';
import { FC, useEffect, useState } from 'react';
import { SiteSelectItem, EnvironmentSelectItem, CreateRenderingHostFormDataProps } from '../data/createRenderingHostFormTypes';
import { formatEnvironments, useEnvs } from '../hooks/useGetEnvironments';
import { useGetSitesCallback } from '../hooks/useGetSites';

export const findSelectedSite = (selectedValue: string, items: SiteSelectItem[] | undefined): SiteSelectItem | null => {
	if (!items?.length) {
		return null;
	}

	return items.find((i) => i.value.toLowerCase() === selectedValue.toLocaleLowerCase()) || null;
};

export const findSelectedEnvironment = (selectedValue: string, items: EnvironmentSelectItem[] | undefined): EnvironmentSelectItem | null => {
	if (!items?.length) {
		return null;
	}

	return items.find((i) => i.value.environmentId.toLowerCase() === selectedValue.toLocaleLowerCase()) || null;
};

export const CreateRenderingHostSiteStep: FC<FormikProps<CreateRenderingHostFormDataProps>> = (props) => {
	const { refetch } = useEnvs(props.values.formData.projectId);
	const { getSites } = useGetSitesCallback();
	const [environmentsSelectOptions, seteEvironmentsSelectOptions] = useState([] as EnvironmentSelectItem[]);
	const [siteSelectOptions, setSiteSelectOptions] = useState([] as SiteSelectItem[]);

	useEffect(() => {
		void refetch().then((envs) => {
			const environments = envs.data?.data;

			if (!environments) {
				return;
			}
			const en = formatEnvironments(environments);
			seteEvironmentsSelectOptions(en);
		});
		setSiteSelectOptions([] as SiteSelectItem[]);
	}, [props.values.formData.projectId, refetch]);

	return (
		<Container maxWidth="420px" padding={0}>
			<Stack direction="column" alignItems="center" spacing={7}>
				<FormControl isRequired={true} isInvalid={false}>
					<FormLabel htmlFor="Environment">
						<T _str="Select environment:" />
					</FormLabel>
					<Select
						id="formData.environmentId"
						options={environmentsSelectOptions}
						value={findSelectedEnvironment(props.values.formData.environmentId, environmentsSelectOptions)}
						onChange={(newValue: SingleValue<EnvironmentSelectItem>) => {
							props.setFieldValue('formData.environmentId', (newValue as EnvironmentSelectItem).value.environmentId);
							props.setFieldValue('formData.environmentName', (newValue as EnvironmentSelectItem).value.environmentName);
							props.setFieldValue('formData.host', (newValue as EnvironmentSelectItem).value.host);
							props.setFieldValue('formData.repositoryBranch', (newValue as EnvironmentSelectItem).value.repositoryBranch);
							props.setFieldValue('formData.siteName', '');
							void getSites('https://' + (newValue as EnvironmentSelectItem).value.host).then((items) => {
								setSiteSelectOptions(items);
							});
						}}
					/>
					<FormErrorMessage>
						<WarningTwoIcon />
					</FormErrorMessage>
				</FormControl>
				<FormControl isRequired={true} isInvalid={false}>
					<FormLabel htmlFor="Site">
						<T _str="Select site to use Rendering host with:" />
					</FormLabel>
					<Select
						id="formData.siteName"
						options={siteSelectOptions}
						value={findSelectedSite(props.values.formData.siteName, siteSelectOptions)}
						onChange={(newValue: SingleValue<SiteSelectItem>) => {
							props.setFieldValue('formData.siteName', (newValue as SiteSelectItem).label);
						}}
					/>
					<FormErrorMessage>
						<WarningTwoIcon />
					</FormErrorMessage>
				</FormControl>
			</Stack>
		</Container>
	);
};

import { Tabs, Flex, TabList, Tab } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FC } from 'react';
import { useTabIndex } from '../ustils/useTabIndex';

export const MainTabs: FC = () => {
	const [currentTabIndex, setTabIndex] = useTabIndex();

	return (
		<Tabs index={currentTabIndex} paddingTop="4" height="fit-content">
			<Flex flexDirection="column" height="fit-content">
				<TabList>
					<Tab onClick={() => setTabIndex(0)} marginLeft="8">
						<T _str="Projects" />
					</Tab>
					<Tab onClick={() => setTabIndex(1)}>
						<T _str="RenderingHosts" />
					</Tab>
				</TabList>
			</Flex>
		</Tabs>
	);
};

import { Button, Container, Image, Stack, Text } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FormikProps } from 'formik';
import { FC, useEffect } from 'react';
import { CreateRenderingHostFormDataProps } from '../data/createRenderingHostFormTypes';
import Gitpod from '../icons/gitpod.svg';

export const CreateRenderingHostGitpodLoginStep: FC<FormikProps<CreateRenderingHostFormDataProps>> = (props) => {
	useEffect(() => {}, [props.values.gitpodIntegration.userLoggedIn]);
	if (props.values.formData.useGitpodRenderingHost) {
		const openInNewTab = (url: string | undefined) => {
			window.open(url, '_blank', 'noopener,noreferrer');
		};

		return (
			<Container maxWidth="420px" padding={0}>
				<Stack direction="column" alignItems="center" paddingBottom="5" spacing={7}>
					<Image src={Gitpod} fill="gray" height={20} />
				</Stack>
				<Stack direction="column" alignItems="left" spacing={7}>
					<Text fontWeight="bold">
						<T _str="You have choosen Gitpod integration for your rendering host. " />
					</Text>
					<Text>
						<T _str="In order to proceed you have to have account in Gitpod and be logged in. Next step requires user authorization in Gitpod, please proceed with login in opened window." />
					</Text>
				</Stack>
				<Stack direction="column" paddingTop="5" alignItems="center" spacing={7}>
					<Button
						variant="secondary"
						size="sm"
						onClick={() => {
							openInNewTab('https://gitpod.io/workspaces');
							props.setFieldValue('gitpodIntegration.userLoggedIn', true);
						}}
					>
						<T _str="Log in to Gitpod" />
					</Button>
				</Stack>
			</Container>
		);
	}
	return <div></div>;
};

import { createApolloClient, useCustomApolloClient } from 'features/common/apolloProvider/customApollo';
import { loader } from 'graphql.macro';
import { useCallback } from 'react';
import { MillerItemType } from '../models/MillerColumnsTypes';
import { SitesResponse } from '../models/site.model';
import { formatAllSites } from '../utils/utils';

type GetSitesCallback = {
	getSites: (host: string) => Promise<MillerItemType[]>;
};

export const getSItesQuery = loader('../queries/getSites.graphql');

export const useGetSitesCallback = (): GetSitesCallback => {
	// const { error, loading, refetch } = useQuery<SitesResponse>(getSItesQuery, {
	// 	skip: true,
	// });

	const { asyncAuthLink, errorLink } = useCustomApolloClient();

	const getSites = useCallback(
		async (host: string): Promise<MillerItemType[]> => {
			const client = createApolloClient(errorLink, asyncAuthLink, host);
			const sites = await client.query<SitesResponse>({ query: getSItesQuery });

			return formatAllSites(sites.data);
		},
		[asyncAuthLink, errorLink]
	);

	return { getSites };
};

import { Container, Image, Text, Stack, Button, Icon } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FormikProps } from 'formik';
import { FC, useEffect, useState } from 'react';
import { CreateRenderingHostFormDataProps } from '../data/createRenderingHostFormTypes';
import { RenderingHostConfiguration } from '../data/renderingHostConfigurationTypes';
import { getWorkspaceRegistrationUrl } from '../utils/utils';
import Gitpod from '../icons/gitpod.svg';
import { useGetWorkspaceById } from '../services/gitpodIntegrationService';
import { sleep } from 'features/common/utils/promiseUtils';
import { mdiCheck } from '@mdi/js';
import { useGetHostConfiguration } from '../services/hostConfigurationService';

export const CreateRenderingHostGitpodWorkspaceStep: FC<FormikProps<CreateRenderingHostFormDataProps>> = (props) => {
	const { refetch: refetchHostConfiguration } = useGetHostConfiguration(props.values.formData.host);
	const [isCreatingWorkspace, setIsCreatigWorkspace] = useState(false);
	const [isCreatingCompleted, setIsCreatingCompleted] = useState<boolean | undefined>();
	const [attempts, setAttempts] = useState(0);
	const { refetch } = useGetWorkspaceById(props.values.gitpodIntegration.ideId);
	const openInNewTab = (url: string | undefined) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	useEffect(() => {
		if (props.values.formData.host && !props.values.gitpodIntegration.isWorkspaceCreated && !isCreatingWorkspace) {
			void refetchHostConfiguration().then((configuration) => {
				const hostLink = 'https://' + props.values.formData.host;
				const workspaceConfiguration: RenderingHostConfiguration = {
					sitecoreApiHost: hostLink,
					graphQLEndpoint: hostLink + '/sitecore/api/graph/edge',
					jssEditingSecret: configuration.data?.data.jssEditingSecret || '',
					sitecoreApiKey: configuration.data?.data.sitecoreApiKey || '',
					repositoryUrl: '',
				};

				const workspaceRegistrationLink = getWorkspaceRegistrationUrl(
					workspaceConfiguration,
					props.values.gitpodIntegration.ideId || '',
					props.values.formData.repositoryUrl || ''
				);
				props.setFieldValue('gitpodIntegration.workspaceRegistrationLink', workspaceRegistrationLink);
			});
		}
	}, [
		props.values.gitpodIntegration.workspaceRegistrationLink,
		props.values.gitpodIntegration.isWorkspaceCreated,
		props.values.formData.host,
		props.values.formData.repositoryUrl,
	]);

	useEffect(() => {
		if (isCreatingWorkspace) {
			const fetchData = async () => {
				await sleep(2000);
				const result = await refetch();
				if (result.data?.data) {
					props.setFieldValue('gitpodIntegration.isWorkspaceCreated', true);
					props.setFieldValue('formData.renderingHostUrl', result.data?.data.renderingHostPublicUrl);
					props.setFieldValue('gitpodIntegration.workspaceUrl', result.data?.data.workspaceUrl);
					setIsCreatigWorkspace(false);
					setIsCreatingCompleted(true);
				}
				setAttempts(attempts + 1);
			};

			fetchData().catch(console.error);
			if (attempts == 20 || props.values.gitpodIntegration.isWorkspaceCreated) {
				setIsCreatigWorkspace(false);
				setIsCreatingCompleted(true);
			}
		}
	}, [isCreatingWorkspace, attempts]);

	if (props.values.formData.useGitpodRenderingHost) {
		return (
			<Container maxWidth="420px" padding={0}>
				<Stack direction="column" alignItems="center" paddingBottom="5" spacing={7}>
					<Image src={Gitpod} fill="gray" height={20} />
				</Stack>
				<Stack direction="column" alignItems="left" spacing={7}>
					<Text fontWeight="bold">
						<T _str="Create Gitpod workspace to work with rendering host" />
					</Text>
					<Text>
						<T _str="This step will create workspace into your gitpod account to use it as a rendering host for the site. This might take a while. You can find gitpod IDE in opened window after process is completed." />
					</Text>
				</Stack>
				<Stack direction="column" paddingTop="5" alignItems="center" spacing={7}>
					{props.values.gitpodIntegration.isWorkspaceCreated ? (
						<Container maxWidth="420px" padding={0}>
							<Icon data-testid="pageIcon" path={mdiCheck} w="15px" h="15px" />
							<Text fontWeight="bold" as="i">
								<T _str="Workspace was created successfully!" />
							</Text>
						</Container>
					) : (
						<Button
							variant="secondary"
							size="sm"
							onClick={() => {
								openInNewTab(props.values.gitpodIntegration.workspaceRegistrationLink);
								setIsCreatigWorkspace(true);
							}}
							disabled={isCreatingCompleted}
							isLoading={isCreatingWorkspace}
							loadingText={'Creating workspace...'}
						>
							<T _str="Create Gitpod workspace" />
						</Button>
					)}
				</Stack>
			</Container>
		);
	}
	return <div></div>;
};

import { SitecoreUIProvider } from '@sitecore-ui/design-system';
import { XMTenantProvider } from 'features/common/tenants/xmTenantProvider';
import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthenticatedAxiosProvider } from './features/common/apiUtils/AxiosProvider';
import { Auth0ProviderWithHistory } from './features/common/auth/Auth0ProviderWithHistory';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
		mutations: {
			retry: false,
		},
	},
});

const AppProviders: FC = ({ children }) => {
	return (
		<>
			<Router>
				<Auth0ProviderWithHistory>
					<SitecoreUIProvider>
						<QueryClientProvider client={queryClient}>
							<AuthenticatedAxiosProvider>
								<XMTenantProvider>{children}</XMTenantProvider>
							</AuthenticatedAxiosProvider>
							{/*<ReactQueryDevtools initialIsOpen={false} /> */}
						</QueryClientProvider>
					</SitecoreUIProvider>
				</Auth0ProviderWithHistory>
			</Router>
		</>
	);
};

export default AppProviders;

import {
	Container,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Input,
	Select,
	Stack,
	WarningTwoIcon,
} from '@sitecore-ui/design-system';
import { SingleValue } from '@sitecore-ui/design-system/lib/components/Select';
import { T } from '@transifex/react';
import { useGetProjectsOrDefault } from 'features/projects/projectsUtils';
import { FormikProps } from 'formik';
import { FC, useEffect } from 'react';
import { ProjectSelectItem, CreateRenderingHostFormDataProps } from '../data/createRenderingHostFormTypes';
import { useGetRepositoryUrl } from '../hooks/useGetWorkspaceConfiguration';
import { formatProjects } from '../utils/utils';

export const findSelected = (selectedValue: string | undefined, items: ProjectSelectItem[]): ProjectSelectItem | undefined => {
	if (!items.length) {
		return undefined;
	}

	return items.find((i) => i.value.id.toLowerCase() === selectedValue?.toLocaleLowerCase());
};

export const CreateRenderingHostProjectStep: FC<FormikProps<CreateRenderingHostFormDataProps>> = (props) => {
	const projects = useGetProjectsOrDefault();
	const { refetch } = useGetRepositoryUrl(props.values.formData.sourceControlIntegrationId);
	const projectSelectOptions = formatProjects(projects.projects);

	useEffect(() => {
		void refetch().then((repositories) => {
			const repositoryData = repositories?.data?.data?.find((i) => i.name === props.values.formData.repository);
			if (repositoryData) {
				props.setFieldValue('formData.repositoryUrl', repositoryData.url);
			} else {
				props.setFieldValue('formData.repositoryUrl', '');
			}
		});
	}, [props.values.formData.projectId]);

	return (
		<Container maxWidth="420px" padding={0}>
			<Stack direction="column" alignItems="center" spacing={7}>
				<FormControl isRequired={true} isInvalid={false}>
					<FormLabel htmlFor="Project">
						<T _str="Choose project to use Rendering host with:" />
					</FormLabel>
					<Select
						id="formData.projectId"
						options={projectSelectOptions}
						value={findSelected(props.values.formData.projectId, projectSelectOptions)}
						onChange={(newValue: SingleValue<ProjectSelectItem>) => {
							props.setFieldValue('formData.projectId', (newValue as ProjectSelectItem).value.id);
							props.setFieldValue('formData.projectName', (newValue as ProjectSelectItem).value.name);
							props.setFieldValue('formData.repository', (newValue as ProjectSelectItem).value.repository);
							props.setFieldValue(
								'formData.sourceControlIntegrationId',
								(newValue as ProjectSelectItem).value.sourceControlIntegrationId
							);
							props.setFieldValue('formData.siteName', '55555');
						}}
					/>
					<FormErrorMessage>
						<WarningTwoIcon />
					</FormErrorMessage>
				</FormControl>
				<FormControl
					isDisabled={!props.values.formData.useGitpodRenderingHost}
					isRequired={props.values.formData.useGitpodRenderingHost}
					isInvalid={!!props.errors.formData?.repositoryUrl}
				>
					<FormLabel htmlFor="repositoryUrl">
						<T _str="Rendering host repository url:" />
					</FormLabel>
					<Input
						type="url"
						name="formData.repositoryUrl"
						id="repositoryUrl"
						value={props.values.formData.repositoryUrl}
						onChange={props.handleChange}
						maxLength={300}
					/>
					<FormErrorMessage>
						<WarningTwoIcon />
						<T _str="Rendering host url can not be empty" />
					</FormErrorMessage>
					<FormHelperText>
						<T _str="Url to source code of Rendering host that will be used" />
					</FormHelperText>
				</FormControl>
			</Stack>
		</Container>
	);
};

import { XMProjectsContext } from 'features/projects/projectsContext';
import { useGetProjectsOrDefault } from 'features/projects/projectsUtils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { MillerItemType } from '../models/MillerColumnsTypes';
import { formatProjects } from '../utils/utils';
import { useGetEnvironmentsCallback } from './useGetEnvironments';
import { useGetSitesCallback } from './useGetSites';

export type MillerData = {
	millerColumns: MillerItemType[][];
	loadData: (item: MillerItemType, index: number) => void;
	selectedProject?: MillerItemType;
	selectedEnvironment?: MillerItemType;
	selectedSite?: MillerItemType;
};

const replaceProjectsColumn = (millerData: MillerItemType[][], projects: MillerItemType[]) => {
	if (millerData.length > 1) {
		return [projects, ...millerData.slice(1, millerData.length)];
	}
	return [projects];
};

const replaceEnvColumn = (millerData: MillerItemType[][], column: MillerItemType[], index: number) => {
	const freshData = millerData.slice(0, index + 1);
	if (column.length > 0) {
		freshData.push(column);
	}
	return freshData;
};

const replaceSitesColumn = (millerData: MillerItemType[][], column: MillerItemType[], index: number) => {
	const freshData = millerData.slice(0, index + 1);
	if (column.length > 0) {
		freshData.push(column);
	}
	return freshData;
};

export const useGetMillerData = (): MillerData => {
	const [millerColumns, setMillerColumns] = useState<MillerItemType[][]>([]);
	const [selectedProject, setSelectedProject] = useState<MillerItemType | undefined>();
	const [selectedEnvironment, setSelectedEnvironment] = useState<MillerItemType | undefined>();
	const [selectedSite, setSelectedSite] = useState<MillerItemType | undefined>();
	const { projects, isLoading } = useGetProjectsOrDefault();
	const { getEnvironments } = useGetEnvironmentsCallback(selectedProject?.id);
	const { getSites } = useGetSitesCallback();
	const xMProjectContext = useContext(XMProjectsContext);

	useEffect(() => {
		if (isLoading) {
			return;
		}
		if (projects) {
			setMillerColumns((oldMillerColumns) => {
				const newProjects = formatProjects(projects);
				return replaceProjectsColumn(oldMillerColumns, newProjects);
			});
		}
	}, [projects, isLoading]);

	const loadData = useCallback(
		(item: MillerItemType, index: number) => {
			if (item.type == 'project') {
				setSelectedProject(item);
				xMProjectContext.model.project.id = item.id;
				xMProjectContext.model.project.name = item.name;
				xMProjectContext.model.project.repository = item.projectDetails?.repository || '';
				xMProjectContext.model.project.sourceControlIntegrationId = item.projectDetails?.sourceControlIntegrationId || '';

				void getEnvironments().then((items) => {
					setMillerColumns((columns) => replaceEnvColumn(columns, items, index));
				});
			}
			if (item.type == 'environment') {
				setSelectedEnvironment(item);
				xMProjectContext.model.environment.environmentId = item.id;
				xMProjectContext.model.environment.environmentName = item.name;
				xMProjectContext.model.environment.host = item.envDetails.host;

				void getSites(item.envDetails.hostLink || '').then((items) => {
					setMillerColumns((columns) => replaceSitesColumn(columns, items, index));
				});
			}
			if (item.type == 'site') {
				setSelectedSite(item);
				xMProjectContext.model.site.name = item.name;

				//setMillerColumns((columns) => replaceEnvColumn(columns, items, index));
			}
		},
		[getEnvironments, getSites]
	);

	return { millerColumns, loadData, selectedProject, selectedEnvironment, selectedSite };
};

import { useGetProjectsOrDefault } from './projectsUtils';

export const useResolveProjects = () => {
	const projectsResponse = useGetProjectsOrDefault();

	return {
		projects: projectsResponse.projects,
		loading: projectsResponse.isLoading,
	};
};

import { Box, Flex } from '@sitecore-ui/design-system';
import { RefObject, useCallback, useRef, useState, ReactNode, ReactElement, useImperativeHandle, MutableRefObject } from 'react';
import classes from './MillerItem.module.css';
import { useColumnScroll } from '../hooks/useColumnScroll';
import { MillerColumn } from './MillerColumn';
import { MillerItemProps } from './MillerItem';
import { MillerItemBase } from '../models/MillerColumnsTypes';

export type MillerItemFactory<T> = (props: MillerItemProps<T>) => ReactNode;

export type MillerActiveIndex = {
	column: number;
	index: number;
};

export type MillerControl<T> = {
	selectItem: (item: T, columnIndex: number) => void;
};

export type MillerColumnsProps<T> = {
	columns: T[][];
	onClick?: (item: T, columnIndex: number, itemIndex: number) => void;
	columnWidth?: number;
	visibleNum?: number;
	millerItemFactory: MillerItemFactory<T>;
	controlRef?: MutableRefObject<MillerControl<T> | undefined>;
};

export const MillerColumns = <T extends MillerItemBase>({
	columns,
	onClick,
	columnWidth = 300,
	millerItemFactory: millerItemFactory,
	controlRef,
}: MillerColumnsProps<T>): ReactElement => {
	const columnsViewRef = useRef<HTMLDivElement | null>();
	const [activeColumn, setActiveColumn] = useState(0);
	const [activeIds, setActiveIds] = useState<Array<string | undefined>>([undefined]);

	const { millerColumnsSize } = useColumnScroll(columns.length, columnWidth, columnsViewRef);

	const updateActiveIds = useCallback(
		(columnIndex: number, itemId: string) => {
			const newActiveIds = [...activeIds.slice(0, columnIndex), itemId];
			setActiveIds(newActiveIds);
		},
		[activeIds]
	);

	const onItemSelected = useCallback(
		(item: T, column: number, index: number) => {
			setActiveColumn(column);
			updateActiveIds(column, item.id);
			if (onClick) {
				onClick(item, column, index);
			}
		},
		[onClick, updateActiveIds]
	);

	useImperativeHandle(
		controlRef,
		() => ({
			selectItem: (item: T, columnIndex: number) => {
				const itemIndex = columns[0].indexOf(item);
				onItemSelected(item, columnIndex, itemIndex);
			},
		}),
		[onItemSelected, columns]
	);

	return (
		<Box data-testid="columns-box" className={classes.wrapper} ref={columnsViewRef as RefObject<HTMLDivElement>}>
			<Flex minW={millerColumnsSize * columnWidth} h="full">
				{columns.map((column: T[], index: number) => (
					<MillerColumn
						key={`${index}`}
						isActiveColumn={index === activeColumn}
						activeId={activeIds[index]}
						w={columnWidth}
						items={column}
						onClick={(item: T, itemIndex: number) => onItemSelected(item, index, itemIndex)}
						millerItemFactory={millerItemFactory}
					/>
				))}
			</Flex>
		</Box>
	);
};

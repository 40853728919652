import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import { IGetUserData } from './userModel';

export const useGetCurrentUser = () => {
	return useAuthQuery(
		['identity.user'],
		async (axiosInstance) => {
			return axiosInstance?.get<IGetUserData>(config.identity.get_user.url);
		},
		{},
		config.identity.get_user.scope
	);
};

import { Container, Stack, Text, Image } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { CreateRenderingHostFormDataProps } from '../data/createRenderingHostFormTypes';
import Racoon from '../icons/raccoon.png';

export const CreateRenderingHostFinalStep: FC<FormikProps<CreateRenderingHostFormDataProps>> = () => {
	return (
		<Container maxWidth="420px" padding={0}>
			<Stack direction="column" alignItems="center" paddingBottom="5" spacing={7}>
				<Image src={Racoon} fill="gray" height={20} />
			</Stack>
			<Stack direction="column" alignItems="left" spacing={7}>
				<Text fontWeight="bold">
					<T _str="Final step for adding rendering host" />
				</Text>
				<Text>
					<T _str="This step will setup the rendering host for the XMCloud site. It might take a while." />
				</Text>
			</Stack>
		</Container>
	);
};

import { useEffect, useMemo } from 'react';
import { useAuthWithClaims } from '../auth/useAuthWithClaims';
import { useTenantParameter } from './tenantParameter';
import { useGetTenantOrDefault } from './tenantsUtils';

export const useResolveTenant = () => {
	const { user } = useAuthWithClaims();
	const orgIdClaim = user?.['https://auth.sitecorecloud.io/claims/org_id'];
	const [tenantNameParameter, storeTenantName] = useTenantParameter(orgIdClaim);

	const error = useMemo(() => {
		if (!orgIdClaim) {
			return new Error('No organization found');
		}

		return undefined;
	}, [orgIdClaim]);

	const expectedTenantName = tenantNameParameter ?? undefined;
	const tenantResponse = useGetTenantOrDefault(orgIdClaim!, expectedTenantName, !!orgIdClaim);
	useEffect(() => {
		if (tenantResponse.tenant) {
			storeTenantName(tenantResponse.tenant.name);
		}
	}, [tenantResponse.tenant, storeTenantName]);

	return {
		tenant: tenantResponse.tenant,
		loading: tenantResponse.isLoading,
		error: error,
	};
};

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Site } from 'features/millerColumns/models';
import { Environment } from 'features/renderingHosts/data/createRenderingHostTypes';
import React, { useMemo } from 'react';
import { Loading } from '../common/components/Loading';
import { XMProjectsContext, XMProjectsModelWrapper } from './projectsContext';
import { IProject } from './projectsModel';

export const XmProjectProvider: React.FC = withAuthenticationRequired(
	({ children }) => {
		//const { projects, loading } = useResolveProjects();

		const xmrojects = useMemo<XMProjectsModelWrapper>(() => {
			return {
				resolved: false,
				model: {
					project: {} as IProject,
					environment: {} as Environment,
					site: {} as Site,
				},
			} as unknown as XMProjectsModelWrapper;
		}, []);

		return <XMProjectsContext.Provider value={xmrojects}>{children}</XMProjectsContext.Provider>;
	},
	{
		onRedirecting: () => <Loading />,
	}
);

import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useSaveNavigate = (): ((pathName: string) => void) => {
	const location = useLocation();
	const navigate = useNavigate();

	return useCallback(
		(pathName: string) => {
			navigate({ ...location, pathname: pathName });
		},
		[location, navigate]
	);
};

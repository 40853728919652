import { ToastProps, useToast } from '@sitecore-ui/design-system';
import { t } from '@transifex/native';
import { AxiosInstance } from 'axios';

import {
	MutationFunction,
	QueryFunction,
	QueryFunctionContext,
	QueryKey,
	useMutation,
	UseMutationOptions,
	UseMutationResult,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { isAxiosError, isError, isErrorMessageResponse, isProblemDetails } from '../utils/errorUtils';
import { useAuthenticatedAxios } from './AxiosProvider';
import { defaultResponseErrorMap, matchError } from './errorsMap';

export const defaultErrorHandler = (err: unknown, scope: string | undefined, toast: (props: ToastProps) => void) => {
	if (isAxiosError(err)) {
		const axiousError = err;
		if (axiousError.response) {
			// The request was made and the server responded with an error status code
			const customErrorMessage = matchError(defaultResponseErrorMap, {
				code: axiousError.response?.status,
				method: axiousError.config.method,
				path: axiousError.config.url,
				scope: scope,
			});

			const responseData = axiousError.response.data;
			let generatedMessage = axiousError.message;
			if (isProblemDetails(responseData)) {
				generatedMessage = responseData.detail;
			} else if (isErrorMessageResponse(responseData)) {
				generatedMessage = responseData.message;
			}

			return toast({
				status: 'error',
				description: customErrorMessage ?? generatedMessage,
			});
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
		if ((err as any).request) {
			// The request was made but no response was received
			if (navigator.onLine === true) {
				return toast({
					status: 'error',
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
					description: t('Server failed to respond. You appear offline and might be the issue.'),
				});
			}

			return toast({
				status: 'error',
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
				description: t('Server failed to respond or taking too long. Can also be issue with firewalls etc. Trying again might help.'),
			});
		}

		// Something happened in setting up the request that triggered an Error
		// Error already logging to console and should be handled elsewhere.
		return;
	}

	if (isError(err)) {
		return toast({
			status: 'error',
			title: err.name,
			description: err.message,
		});
	}

	if (typeof err === 'string') {
		return toast({ status: 'error', description: err });
	}
};

export type UseAuthQueryOptions = Omit<UseQueryOptions, 'queryKey' | 'queryFn'>;

/**
 * Needs to be used inside Auth0Context and AxiosProvider
 */
export const useAuthQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
	key: TQueryKey,
	fetcher: (
		axiosInstanceWithAuth: AxiosInstance,
		queryFnContext?: QueryFunctionContext<QueryKey, TQueryKey>
	) => TQueryFnData | Promise<TQueryFnData>,
	options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
	scope?: string
): UseQueryResult<TData, TError> => {
	const toast = useToast();
	const axiosContext = useAuthenticatedAxios();
	if (!axiosContext) throw new Error('useAuthQuery can not be used outside of auth and axios context');

	const enhancedFetcher: QueryFunction<TQueryFnData, TQueryKey> = (params) => fetcher(axiosContext, params);
	options = options || {};

	const onErrorCallback = options.onError;

	options.onError = (err) => {
		if (onErrorCallback) {
			onErrorCallback(err);
		}

		defaultErrorHandler(err, scope, toast);
	};

	return useQuery<TQueryFnData, TError, TData, TQueryKey>(key, enhancedFetcher, options);
};

/**
 * Needs to be used inside Auth0Context and AxiosProvider
 */
export const useAuthMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
	mutationFn: (axiosInstanceWithAuth: AxiosInstance) => MutationFunction<TData, TVariables>,
	options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
	scope?: string
): UseMutationResult<TData, TError, TVariables, TContext> => {
	const toast = useToast();
	const axiosContext = useAuthenticatedAxios();

	if (!axiosContext) throw new Error('useAuthMutation can not be used outside of auth and axios context');

	options = options || {};

	const defaultErrorCallback = options.onError;

	options.onError = (err, variables, context) => {
		if (defaultErrorCallback) {
			void defaultErrorCallback(err, variables, context);
		}
		defaultErrorHandler(err, scope, toast);
	};

	const enhancedMutationFn: MutationFunction<TData, TVariables> = mutationFn(axiosContext);
	return useMutation<TData, TError, TVariables, TContext>(enhancedMutationFn, options);
};

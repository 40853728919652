import { MainContainer } from './features/main/components/MainContainer';
import AppProviders from './AppProviders';

const App = () => {
	return (
		<AppProviders>
			<MainContainer />
		</AppProviders>
	);
};

export default App;

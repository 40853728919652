import { useEnvs } from 'features/renderingHosts/hooks/useGetEnvironments';
import { useCallback } from 'react';
import { MillerItemType } from '../models/MillerColumnsTypes';
import { formatEnvironments } from '../utils/utils';

type GetEnvironmentCallback = {
	loading: boolean;
	getEnvironments: () => Promise<MillerItemType[]>;
};

export const useGetEnvironmentsCallback = (projectId: string | undefined): GetEnvironmentCallback => {
	const { isLoading, refetch } = useEnvs(projectId);

	const getEnvironments = useCallback(async (): Promise<MillerItemType[]> => {
		const envs = (await refetch()).data?.data;
		if (envs) {
			return formatEnvironments(envs);
		}
		return [];
	}, [refetch]);

	return { loading: isLoading, getEnvironments };
};

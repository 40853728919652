import {
	Button,
	ButtonProps,
	Icon,
	IconButton,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Text,
} from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FC, useCallback } from 'react';
import { CreateRenderingHostForm } from '../forms/CreateRenderingHostForm';
import { mdiArrowLeft } from '@mdi/js';

type CreateRenderingHostButtonProps = {
	buttonProps: ButtonProps;
	refetchRenderingHostsList: () => void;
};

export const CreateRenderingHostButton: FC<CreateRenderingHostButtonProps> = ({ buttonProps, refetchRenderingHostsList, children }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const renderingHostSaved = useCallback(() => {
		onClose();
		refetchRenderingHostsList();
	}, [onClose, refetchRenderingHostsList]);

	return (
		<>
			<Button {...buttonProps} onClick={onOpen}>
				{children}
			</Button>

			<Modal isOpen={isOpen} onClose={onClose} size="full">
				<ModalOverlay>
					<ModalContent height="30rem" background="gray.50">
						<ModalHeader fontSize="3xl">
							<IconButton
								aria-label="close-create-rendering-host-dialog"
								size="sm"
								mr={2}
								verticalAlign="bottom"
								icon={<Icon path={mdiArrowLeft}></Icon>}
								onClick={onClose}
							></IconButton>
							<Text as="span">
								<T _str="Add rendering host" />
							</Text>
						</ModalHeader>
						<ModalCloseButton />
						<CreateRenderingHostForm closeModal={renderingHostSaved} />
					</ModalContent>
				</ModalOverlay>
			</Modal>
		</>
	);
};

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { t } from '@transifex/native';
import { useCallback } from 'react';

// Transiflex library define all functions with type any. To avoid linting errors, this hook was added.
export const useTranslate = (): ((text: string) => string) => {
	return useCallback((text: string) => {
		return t(text) as string;
	}, []);
};

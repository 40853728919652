import axios from 'axios';
import { createContext, FC, useContext } from 'react';
import { useAuthWithClaims } from '../auth/useAuthWithClaims';
import { createAuthAxios } from './customAxios';

export const AxiosContext = createContext(axios.create());

// Needs to be used inside Auth0Context
export const AuthenticatedAxiosProvider: FC = (props) => {
	const { getAccessTokenSilently } = useAuthWithClaims();

	const authAxios = createAuthAxios(axios.create());
	authAxios.interceptors.request.use(async (request) => {
		const token = await getAccessTokenSilently();

		if (request && request.headers) {
			request.headers.Authorization = 'Bearer ' + token;
		}

		return request;
	});
	return <AxiosContext.Provider value={authAxios}>{props.children}</AxiosContext.Provider>;
};

export const useAuthenticatedAxios = () => {
	return useContext(AxiosContext);
};

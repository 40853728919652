import { useCallback, useState } from 'react';
import { ItemViewModelPoc } from '../models';
import { MillerItemType } from '../models/MillerColumnsTypes';
import { formatDetailedItem } from '../utils/utils';

export type ItemType = 'site' | 'environment' | 'project';

export type DetailedItemBase = {
	currentItem: ItemViewModelPoc | undefined;
	currentSite?: string;
	itemType: ItemType;
	loading?: boolean;
};

export type DetailedItemResult = {
	onItemSelected: (item: MillerItemType) => void;
} & DetailedItemBase;

export const useGetDetailedItem = (): DetailedItemResult => {
	const [currentItem, setCurrentItem] = useState<ItemViewModelPoc | undefined>();
	const [itemType, setItemType] = useState<ItemType>('project');
	const onItemSelected = useCallback((item: MillerItemType) => {
		setItemType(item.type);
		const detaileItem = item;
		const model = detaileItem && formatDetailedItem(detaileItem);
		if (model && item.thumbnailUrl) {
			model.thumbnailUrl = item.thumbnailUrl;
		}
		setCurrentItem(model);
	}, []);

	return {
		currentItem: currentItem,
		onItemSelected: onItemSelected,
		itemType: itemType,
	};
};

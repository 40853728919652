import { useCallback, useState } from 'react';
import { RenderingHost } from '../models/millerItemModel';

export type DetailedItemBase = {
	currentItem: RenderingHost | undefined;
	loading?: boolean;
};

export type DetailedItemResult = {
	onItemSelected: (item: RenderingHost) => void;
} & DetailedItemBase;

export const useGetDetailedItem = (): DetailedItemResult => {
	const [currentItem, setCurrentItem] = useState<RenderingHost | undefined>();

	const onItemSelected = useCallback((item: RenderingHost) => {
		setCurrentItem(item);
	}, []);

	return {
		currentItem: currentItem,
		onItemSelected: onItemSelected,
	};
};

import { Button, ButtonGroup, Container, ModalBody, Stack, TabPanel, TabPanels, Tabs } from '@sitecore-ui/design-system';
import { Formik } from 'formik';
import { FC, useCallback, useState } from 'react';
import { CreateRenderingHostFormTabList } from '../components/CreateRenderingHostFormTabList';
import { CreateRenderingHostDeploymentStep } from '../components/CreateRenderingHostDeploymentStep';
import { T } from '@transifex/react';
import { CreateRenderingHostProjectStep } from '../components/CreateRenderingHostProjectStep';
import { CreateRenderingHostSiteStep } from '../components/CreateRenderingHostSiteStep';
import { CreateRenderingHostGitpodLoginStep } from '../components/CreateRenderingHostGitpodLoginStep';
import { CreateRenderingHostGitpodWorkspaceStep } from '../components/CreateRenderingHostGitpodWorkspaceStep';
import { CreateRenderingHostFinalStep } from '../components/CreateRenderingHostFinalStep';
import { CreateRenderingHostFormDataProps, getInitialRenderingHostFormDefaultValues, makeid } from '../data/createRenderingHostFormTypes';
import { formatCreateRenderingFormToPayload } from '../utils/utils';
import { useCreateRenderingHost } from '../services/renderingHostManagmentService';

type CreateRenderingHostFormProps = {
	closeModal: () => void;
};

export const CreateRenderingHostForm: FC<CreateRenderingHostFormProps> = ({ closeModal }) => {
	const [currentTab, setCurrentTab] = useState(0);
	const createRenderingHost = useCreateRenderingHost();

	const initValues = getInitialRenderingHostFormDefaultValues();
	initValues.gitpodIntegration.ideId = makeid(10);
	const handleRenderingHostCreation = useCallback(
		async function (values: CreateRenderingHostFormDataProps) {
			try {
				// eslint-disable-next-line no-empty
				if (values.hasErrors) {
				} else {
					const payload = formatCreateRenderingFormToPayload(values);
					await createRenderingHost(payload, 30);
				}
				closeModal();
			} catch (e: any) {
				// ignore
			}
		},
		[closeModal, createRenderingHost]
	);

	return (
		<Formik
			initialValues={initValues}
			onSubmit={handleRenderingHostCreation}
			validateOnChange={false}
			validateOnBlur={false}
			validateOnMount={false}
			//validationSchema={siteValidationSchema}
		>
			{(formikProps) => (
				<>
					<ModalBody overflowY="auto">
						<Tabs index={currentTab} align="center">
							<CreateRenderingHostFormTabList />
							<TabPanels textAlign="left">
								<TabPanel paddingTop="14">
									<CreateRenderingHostDeploymentStep {...formikProps}></CreateRenderingHostDeploymentStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												<Button variant="primary" onClick={() => setCurrentTab(currentTab + 1)}>
													<T _str="Next step" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
								</TabPanel>
								<TabPanel paddingTop="14">
									<CreateRenderingHostProjectStep {...formikProps}></CreateRenderingHostProjectStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												<Button variant="primary" onClick={() => setCurrentTab(currentTab - 1)}>
													<T _str="Previos step" />
												</Button>
												<Button variant="primary" onClick={() => setCurrentTab(currentTab + 1)}>
													<T _str="Next step" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
								</TabPanel>
								<TabPanel paddingTop="14">
									<CreateRenderingHostSiteStep {...formikProps}></CreateRenderingHostSiteStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												<Button variant="primary" onClick={() => setCurrentTab(currentTab - 1)}>
													<T _str="Previos step" />
												</Button>
												<Button
													variant="primary"
													onClick={() => {
														let nextTabIndex = currentTab + 1;
														if (!formikProps.values.formData.useGitpodRenderingHost) {
															nextTabIndex = currentTab + 3;
														}
														setCurrentTab(nextTabIndex);
													}}
												>
													<T _str="Next step" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
								</TabPanel>
								<TabPanel paddingTop="14">
									<CreateRenderingHostGitpodLoginStep {...formikProps}></CreateRenderingHostGitpodLoginStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												<Button variant="primary" onClick={() => setCurrentTab(currentTab - 1)}>
													<T _str="Previos step" />
												</Button>
												<Button
													variant="primary"
													disabled={!formikProps.values.gitpodIntegration.userLoggedIn}
													onClick={() => setCurrentTab(currentTab + 1)}
												>
													<T _str="Next step" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
									<TabPanel />
								</TabPanel>
								<TabPanel paddingTop="14">
									<CreateRenderingHostGitpodWorkspaceStep {...formikProps}></CreateRenderingHostGitpodWorkspaceStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												<Button
													variant="primary"
													disabled={formikProps.values.gitpodIntegration.isWorkspaceCreated}
													onClick={() => setCurrentTab(currentTab - 1)}
												>
													<T _str="Previos step" />
												</Button>
												<Button
													variant="primary"
													disabled={!formikProps.values.gitpodIntegration.isWorkspaceCreated}
													onClick={() => setCurrentTab(currentTab + 1)}
												>
													<T _str="Next step" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
									<TabPanel />
								</TabPanel>
								<TabPanel paddingTop="14">
									<CreateRenderingHostFinalStep {...formikProps}></CreateRenderingHostFinalStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												{!formikProps.values.formData.useGitpodRenderingHost ? (
													<Button variant="primary" onClick={() => setCurrentTab(currentTab - 3)}>
														<T _str="Previos step" />
													</Button>
												) : (
													<></>
												)}
												<Button
													variant="primary"
													onClick={() => void formikProps.submitForm()}
													isLoading={formikProps.isSubmitting}
													loadingText={'Creating rendering host...'}
												>
													<T _str="Create rendering host" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
									<TabPanel />
								</TabPanel>
							</TabPanels>
						</Tabs>
					</ModalBody>
				</>
			)}
		</Formik>
	);
};

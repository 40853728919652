import { Button, ButtonGroup, Container, ModalBody, Stack, Tab, TabPanel, TabPanels, Tabs } from '@sitecore-ui/design-system';
import { Formik } from 'formik';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { CreateRenderingHostDeploymentStep } from '../components/CreateRenderingHostDeploymentStep';
import { T } from '@transifex/react';
import { CreateRenderingHostGitpodLoginStep } from '../components/CreateRenderingHostGitpodLoginStep';
import { CreateRenderingHostGitpodWorkspaceStep } from '../components/CreateRenderingHostGitpodWorkspaceStep';
import { CreateRenderingHostFinalStep } from '../components/CreateRenderingHostFinalStep';
import { CreateRenderingHostFormDataProps, getInitialRenderingHostFormDefaultValues } from '../data/createRenderingHostFormTypes';
import { formatCreateRenderingFormToPayload, getWorkspaceRegistrationUrl } from '../utils/utils';
import { useCreateRenderingHost } from '../services/renderingHostManagmentService';
import { XMProjectsContext } from 'features/projects/projectsContext';
import { useGetRepositoryUrl } from '../hooks/useGetWorkspaceConfiguration';
import { useGetHostConfiguration } from '../services/hostConfigurationService';
import { RenderingHostConfiguration } from '../data/renderingHostConfigurationTypes';

type CreateRenderingHostFormProps = {
	closeModal: () => void;
};

export const AssignRenderingHostToSiteForm: FC<CreateRenderingHostFormProps> = ({ closeModal }) => {
	const [currentTab, setCurrentTab] = useState(0);
	const createRenderingHost = useCreateRenderingHost();
	const xMProjectContext = useContext(XMProjectsContext);
	const { refetch: refetchHostConfiguration } = useGetHostConfiguration(xMProjectContext.model.environment.host);
	const { refetch } = useGetRepositoryUrl(xMProjectContext.model.project.sourceControlIntegrationId);

	const initValues = getInitialRenderingHostFormDefaultValues();

	initValues.formData.projectId = xMProjectContext.model.project.id;
	initValues.formData.projectName = xMProjectContext.model.project.name;
	initValues.formData.repository = xMProjectContext.model.project.repository;
	initValues.formData.sourceControlIntegrationId = xMProjectContext.model.project.sourceControlIntegrationId;
	initValues.formData.environmentId = xMProjectContext.model.environment.environmentId;
	initValues.formData.environmentName = xMProjectContext.model.environment.environmentName;
	initValues.formData.host = xMProjectContext.model.environment.host;
	initValues.formData.siteName = xMProjectContext.model.site.name;

	const handleRenderingHostCreation = useCallback(
		async function (values: CreateRenderingHostFormDataProps) {
			try {
				if (values.hasErrors) {
					// error message here
				} else {
					const payload = formatCreateRenderingFormToPayload(values);
					await createRenderingHost(payload, 30);
				}
				closeModal();
			} catch (e: any) {
				// ignore
			}
		},
		[closeModal, createRenderingHost]
	);

	useEffect(() => {
		void refetch().then((repositories) => {
			const repositoryData = repositories?.data?.data?.find((i) => i.name === xMProjectContext.model.project.repository);
			if (repositoryData) {
				xMProjectContext.model.project.repositoryUrl = repositoryData.url;
				initValues.formData.repositoryUrl = repositoryData.url;
			}
		});

		if (xMProjectContext.model.environment.host) {
			void refetchHostConfiguration().then((configuration) => {
				const hostLink = 'https://' + xMProjectContext.model.environment.host;
				const workspaceConfiguration: RenderingHostConfiguration = {
					sitecoreApiHost: hostLink,
					graphQLEndpoint: hostLink + '/sitecore/api/graph/edge',
					jssEditingSecret: configuration.data?.data.jssEditingSecret || '',
					sitecoreApiKey: configuration.data?.data.sitecoreApiKey || '',
					repositoryUrl: '',
				};

				const workspaceRegistrationLink = getWorkspaceRegistrationUrl(
					workspaceConfiguration,
					initValues.gitpodIntegration.ideId,
					xMProjectContext.model.project.repositoryUrl || ''
				);
				initValues.gitpodIntegration.workspaceRegistrationLink = workspaceRegistrationLink;
			});
		}
	}, [refetchHostConfiguration, refetch, xMProjectContext.model.environment.host]);

	return (
		<Formik
			initialValues={initValues}
			onSubmit={handleRenderingHostCreation}
			validateOnChange={false}
			validateOnBlur={false}
			validateOnMount={false}
			//validationSchema={siteValidationSchema}
		>
			{(formikProps) => (
				<>
					<ModalBody overflowY="auto">
						<Tabs index={currentTab} align="center">
							<Tab fontWeight="semibold" paddingX={4} borderBottom="2px solid" borderColor="blackAlpha.200">
								<T _str="1: Setup rendering host" />
							</Tab>
							<TabPanels textAlign="left">
								<TabPanel paddingTop="14">
									<CreateRenderingHostDeploymentStep {...formikProps}></CreateRenderingHostDeploymentStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												<Button
													variant="primary"
													onClick={() => {
														if (formikProps.values.formData.useGitpodRenderingHost) {
															setCurrentTab(currentTab + 1);
														} else {
															setCurrentTab(currentTab + 3);
														}
													}}
												>
													<T _str="Next step" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
								</TabPanel>
								<TabPanel paddingTop="14">
									<CreateRenderingHostGitpodLoginStep {...formikProps}></CreateRenderingHostGitpodLoginStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												<Button variant="primary" onClick={() => setCurrentTab(currentTab - 1)}>
													<T _str="Previos step" />
												</Button>
												<Button
													variant="primary"
													disabled={!formikProps.values.gitpodIntegration.userLoggedIn}
													onClick={() => setCurrentTab(currentTab + 1)}
												>
													<T _str="Next step" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
									<TabPanel />
								</TabPanel>
								<TabPanel paddingTop="14">
									<CreateRenderingHostGitpodWorkspaceStep {...formikProps}></CreateRenderingHostGitpodWorkspaceStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												<Button
													variant="primary"
													disabled={formikProps.values.gitpodIntegration.isWorkspaceCreated}
													onClick={() => setCurrentTab(currentTab - 1)}
												>
													<T _str="Previos step" />
												</Button>
												<Button
													variant="primary"
													disabled={!formikProps.values.gitpodIntegration.isWorkspaceCreated}
													onClick={() => setCurrentTab(currentTab + 1)}
												>
													<T _str="Next step" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
									<TabPanel />
								</TabPanel>
								<TabPanel paddingTop="14">
									<CreateRenderingHostFinalStep {...formikProps}></CreateRenderingHostFinalStep>
									<Container maxWidth="420px" padding={0}>
										<Stack direction="column" alignItems="center" spacing={7}>
											<ButtonGroup paddingTop={12}>
												{!formikProps.values.formData.useGitpodRenderingHost ? (
													<Button variant="primary" onClick={() => setCurrentTab(currentTab - 3)}>
														<T _str="Previos step" />
													</Button>
												) : (
													<></>
												)}
												<Button
													variant="primary"
													onClick={() => void formikProps.submitForm()}
													isLoading={formikProps.isSubmitting}
													loadingText={'Creating rendering host...'}
												>
													<T _str="Create rendering host" />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
									<TabPanel />
								</TabPanel>
							</TabPanels>
						</Tabs>
					</ModalBody>
				</>
			)}
		</Formik>
	);
};

export const DICTIONARY = {
	LOGOUT: 'Log out',
	SITES: 'Sites',
	LOADING: 'Loading…',
	CONTENT: 'Content',
	DETAILS: 'Details',
	VERSIONS: 'Versions',
	ARE_YOU_SURE: 'Are you sure?',
	CONFIRM: 'Confirm',
	CANCEL: 'Cancel',
	WORKFLOW_POPOVER_TEXTAREA: 'Add optional comment',
	COMMAND_BTN_TEXT: 'Something',
	REJECT: 'Reject',
	APPROVE: 'Approve',
	ACCESS_DENIED_TITLE: 'Access denied',
	ACCESS_DENIED_TEXT: "You don't have permission to access this page",
	ACCESS_DENIED_SUBTEXT: 'Error 403',
	ACCESS_DENIED_VARIANT: 'access-denied',
	ACCESS_DENIED_LINK_TEXT: 'Go to Homepage',
	DESIGN_PAGE: 'Design Page',
	ITEM_ID: 'Item ID:',
	ITEM_NAME: 'Item name:',
	ITEM_PATH: 'Item path:',
	TEMPLATE: 'Template:',
	ITEM_OWNER: 'Item owner:',
	DATE_OF_CREATION: 'Date of creation:',
	UPDATED_BY: 'Updated by:',
	DATE_OF_UPDATE: 'Date of update:',
	WORKFLOW: 'Workflow:',
	NO_WORKFLOW: 'No Workflow',
	SHOW: 'Show',
	SHOW_SETTING: {
		LANGUAGES: 'Languages',
		WORKFLOW_STATE: 'Workflow State',
		THUMBNAILS: 'Thumbnails',
	},
};

export const LOGO_SRC = 'https://mss-p-006-delivery.stylelabs.cloud/api/public/content/2b9819578b09453c96dea412146d4752?v=a26d9317';

export const NORMALIZE_ID_WITHOUT_DASH_REGEX = /[{}-]/g;

export const SMOOTH_SCROLL_TIMEOUT = 350;

export const DEFAULT_CONTEXT = { itemId: '110d559f-dea5-42ea-9c1c-8a5df7e70ef9', language: 'en', siteName: 'website' };

export const CLIENT_LANGUAGE = 'en';

export const DATE_FORMAT = 'LLL d yyyy, h:mm:ss a';

export const TENANT_COOKIE_NAME = 'explorer.tenantName';

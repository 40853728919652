import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const tabs = [
	{
		name: '',
		index: 0,
	},
	{
		name: 'renderingHosts',
		index: 1,
		alternateName: '1',
	},
];

const findTabByName = (tabName: string) => tabs.find((t) => t.name === tabName || t.alternateName === tabName)?.index || 0;

export const useTabIndex = (): [number, (index: number) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const navigate = useNavigate();
	const tabName = location.pathname.split('/')[1];

	const [tabIndex, setTabIndex] = useState((): number => {
		return findTabByName(tabName);
	});

	const setCurrentTabIndex = useCallback(
		(index: number) => {
			if (index === tabIndex) {
				return;
			}

			setTabIndex(index);
			const newTabName = tabs.find((t) => t.index === index)?.name || tabs[0].name;
			navigate({ ...location, pathname: `/${newTabName}` });
		},
		[tabIndex, navigate, location]
	);

	useEffect(() => {
		const tab = searchParams.get('tab');
		if (!tabName && tab) {
			searchParams.delete('tab');
			navigate({ pathname: `/${tab}`, search: searchParams.toString() });
			setTabIndex(findTabByName(tab));
		} else if (tab) {
			searchParams.delete('tab');
			setSearchParams(searchParams);
		}
	}, [navigate, searchParams, setSearchParams, tabName]);

	return [tabIndex, setCurrentTabIndex];
};

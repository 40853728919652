import { MillerItemType } from 'features/millerColumns/models/MillerColumnsTypes';
import { createContext, useContext } from 'react';

export type XMTenantModel = {
	id: string;
	name: string;
	url: string;
	cdpId?: string;
};

export type XMTenantData = {
	tenant: XMTenantModel;
	error: Error | undefined;
};

export type XMTenantDataUndefined = {
	tenant: XMTenantModel | undefined;
	error: Error | undefined;
};

export type XMTenantModelWrapper =
	| {
			resolved: true;
			model: XMTenantModel;
			error: Error | undefined;
	  }
	| { resolved: false; model: undefined; error: Error | undefined };

export const XMTenantContext = createContext<XMTenantModelWrapper | undefined>(undefined);

export function updateContext(context: XMTenantModelWrapper | undefined, selectedEnvironment: MillerItemType | undefined): void {
	if (context && selectedEnvironment) {
		const newModel: XMTenantModel = {
			id: selectedEnvironment.id,
			name: selectedEnvironment.envDetails.platformTenantName,
			url: selectedEnvironment.envDetails.hostLink,
		};
		context.model = newModel;
	}
}

export function useXMTenant(): XMTenantData;
export function useXMTenant(optional: false): XMTenantData;
export function useXMTenant(optional: true): XMTenantDataUndefined;
export function useXMTenant(optional = false): XMTenantDataUndefined {
	const xmtenantContext = useContext(XMTenantContext);
	if (!xmtenantContext) {
		return {
			tenant: undefined,
			error: new Error('useXMTenant must be used within a XMTenantProvider'),
		};
	}
	if (optional) {
		return {
			tenant: xmtenantContext.model,
			error: xmtenantContext.error,
		};
	}

	if (!xmtenantContext.resolved) {
		return {
			tenant: undefined,
			error: new Error('xmTenant is not resolved'),
		};
	}

	return {
		tenant: xmtenantContext.model,
		error: xmtenantContext.error,
	};
}

import { Box, Text } from '@sitecore-ui/design-system';
import { FC } from 'react';

export const FieldDetails: FC<{ itemType: string; name: string; value: string | undefined; alt?: string }> = ({ itemType, name, value, alt }) => {
	if (value === undefined && alt === undefined) {
		return <></>;
	}

	const lableName = itemType + ' ' + name;
	return (
		<Box textAlign="left">
			<Text>
				<Text as="i" fontWeight="bold">
					{lableName}
				</Text>
				<Text as="i"> </Text>
				<Text as="i" whiteSpace="pre-wrap">
					{value || alt}
				</Text>
			</Text>
		</Box>
	);
};

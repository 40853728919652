import { mdiViewCompact } from '@mdi/js';
import { Box, Button, ButtonProps, CardImageProps, Center, Text, TextProps, Icon } from '@sitecore-ui/design-system';
import { FC } from 'react';
import { RenderingHost } from '../models/millerItemModel';

export interface MillerItemProps<T> {
	data: T;
	isActiveItem: boolean;
	onClick: () => void;
}

const iconProps: TextProps = {
	display: 'inline-flex',
	fontSize: 22,
	height: 22,
	lineHeight: 22,
};

const buttonProps: ButtonProps = {
	variant: 'minimal',
	borderRadius: 10,
	justifyContent: 'flex-start',
	height: 'auto',
	w: '100%',
	minHeight: '60px',
	textAlign: 'left',
	p: 2,
};

const thumbnailurlProps: CardImageProps = {
	borderRadius: '4',
	w: '44px',
	h: '44px',
};

export const MillerItem: FC<MillerItemProps<RenderingHost>> = ({ data, isActiveItem, onClick }) => {
	let bgColor = 'white';

	if (isActiveItem) {
		bgColor = 'primary.100';
	}

	return (
		<Button data-testid="item-button" data-id={data.id} bgColor={bgColor} onClick={onClick} {...buttonProps}>
			<Box w="44px" d="flex" mr="15px" data-testid="item-thumbnail">
				<Center {...thumbnailurlProps} bgColor="gray.100">
					<Text {...iconProps}>{<Icon data-testid="pageIcon" path={mdiViewCompact} w="24px" h="24px" />}</Text>
				</Center>
			</Box>
			<Box flex="1" alignItems="start" isTruncated>
				<Text isTruncated>{data.name}</Text>
			</Box>
		</Button>
	);
};

import { Flex, Spacer } from '@sitecore-ui/design-system';
import { DetailedItemBase } from 'features/millerColumns/hooks/useGetDetailedItem';
import { FC } from 'react';
import { ButtonGroup } from './ButtonGroup';

export const ActionsPanel: FC<DetailedItemBase> = ({ currentItem, itemType, currentSite }) => {
	const noActions = !currentItem && itemType != 'site';
	return (
		<Flex direction="row" height="12" paddingTop="5" paddingRight="7" gap="3">
			<Spacer />
			{noActions ? <></> : <ButtonGroup currentItem={currentItem} itemType={itemType} currentSite={currentSite} />}
		</Flex>
	);
};

import { IProject } from 'features/projects/projectsModel';
import {
	CreateRenderingHostFormDataProps,
	CreateRenderingHostPayload,
	DeployRenderingHostSelectItem,
	ProjectSelectItem,
} from '../data/createRenderingHostFormTypes';
import { RenderingHostConfiguration } from '../data/renderingHostConfigurationTypes';

export const useGetrenderingHostDeloymentOptions = (): DeployRenderingHostSelectItem[] => {
	const deploymentOptions: DeployRenderingHostSelectItem[] = [
		{
			value: false,
			label: 'Use deployed rendering host',
		},
		{
			value: true,
			label: 'Use Gitpod integration',
		},
	];

	return deploymentOptions;
};

export const formatProjects = (projects: IProject[] | undefined): ProjectSelectItem[] => {
	if (!projects || projects.length === 0) {
		return [];
	}

	return projects.map(
		(project: IProject) =>
			({
				value: {
					id: project.id,
					name: project.name,
					repository: project.repository,
					sourceControlIntegrationId: project.sourceControlIntegrationId,
					sourceControlIntegrationName: project.sourceControlIntegrationName,
				},
				label: project.name,
			} as ProjectSelectItem)
	);
};

export const getWorkspaceRegistrationUrl = (workspaeConfiguration: RenderingHostConfiguration, ideId: string, repositoryUrl: string): string => {
	let resultUrl = 'https://gitpod.io/#SITECORE_API_KEY=';

	resultUrl = `${resultUrl + workspaeConfiguration.sitecoreApiKey},SITECORE_API_HOST=${encodeURIComponent(
		workspaeConfiguration.sitecoreApiHost
	)},GRAPH_QL_ENDPOINT=${encodeURIComponent(workspaeConfiguration.graphQLEndpoint)},IDE_ID=${encodeURIComponent(
		ideId
	)},JSS_EDITING_SECRET=${encodeURIComponent(workspaeConfiguration.jssEditingSecret)}/${repositoryUrl}`;

	return resultUrl;
};

export const formatCreateRenderingFormToPayload = (props: CreateRenderingHostFormDataProps): CreateRenderingHostPayload => {
	if (props) {
		const payload = {
			name: props.formData.renderingHostName,
			renderingHostUrl: props.formData.renderingHostUrl,
			environmentId: props.formData.environmentId,
			environmentName: props.formData.environmentName,
			siteName: props.formData.siteName,
			host: props.formData.host,
			repositoryUrl: props.formData.repositoryUrl,
			workspaceUrl: props.gitpodIntegration.workspaceUrl,
			renderingHostHostingMethod: {},
		} as CreateRenderingHostPayload;
		if (props.formData.useGitpodRenderingHost) {
			payload.renderingHostHostingMethod = 'gitpod';
		} else {
			payload.renderingHostHostingMethod = 'external';
		}

		return payload;
	} else {
		return {} as CreateRenderingHostPayload;
	}
};

import { Button, EditIcon, ViewDashboardIcon } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DetailedItemBase } from '../../hooks/useGetDetailedItem';

export const ButtonGroup: FC<DetailedItemBase> = ({ currentItem }) => {
	const openInNewTab = (url: string | undefined) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<>
			<Button
				variant="secondary"
				rightIcon={<EditIcon />}
				size="sm"
				onClick={() => openInNewTab(currentItem?.workspaceUrl)}
				disabled={currentItem?.renderingHostHostingMethod.toLocaleLowerCase() !== 'gitpod'}
			>
				<T _str="Open IDE" />
			</Button>
			<Button variant="secondary" rightIcon={<ViewDashboardIcon />} size="sm" onClick={() => openInNewTab(currentItem?.renderingHostUrl)}>
				<T _str="Open rendering host" />
			</Button>
		</>
	);
};

import { AxiosResponse } from 'axios';
import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import config from 'features/common/config/config';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from 'react-query';
import { RenderingHostConfiguration } from '../data/renderingHostConfigurationTypes';

export type RefechCallbackHostConfiguration = <TPageData>(
	options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<QueryObserverResult<AxiosResponse<RenderingHostConfiguration>, unknown>>;

export const useGetHostConfiguration = (host: string): { isLoading: boolean; refetch: RefechCallbackHostConfiguration } => {
	const { isLoading, refetch } = useAuthQuery(['hostConfiguration', host], async (axiosInstance) => {
		if (host) {
			const result = axiosInstance.get<RenderingHostConfiguration>(config.hostConfigurationUrl.get_hostConfigurationUrl.url(host));

			return result;
		}
		return {} as Promise<AxiosResponse<RenderingHostConfiguration>>;
	});

	return { isLoading, refetch };
};

import { Stack, Box, VStack, Image, Text } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FC } from 'react';
import { useResolveProjects } from '../projectResolver';
import Cactus from '../icons/illus-cactus.svg';
import { sitecoreSizes } from 'features/common/styles/sizes';
import { DashboardLoadingScreen } from 'features/common/components/DashboardLoadingScreen';
import { MillerContainer } from 'features/millerColumns/components/MillerContainer';
import { XmProjectProvider } from '../xmProjectProvider';

export const ProjectsLayout: FC = () => {
	const { projects, loading } = useResolveProjects();

	if (loading) {
		return (
			<Box marginTop={sitecoreSizes.size48}>
				<DashboardLoadingScreen text="Loading projects..." />
			</Box>
		);
	}

	if (!projects?.length) {
		return (
			<VStack>
				<Box height={28} />
				<Image src={Cactus} fill="gray" height={20} />
				<Text color="blackAlpha.800" fontWeight="bold">
					<T _str="No Projects created yet"></T>
				</Text>
				<Text color="blackAlpha.500">
					<T _str="Be the first to create one"></T>
				</Text>
			</VStack>
		);
	}

	return (
		<Stack direction="column" flexGrow={1} spacing="0" height="100%">
			<XmProjectProvider>
				<Box>{<MillerContainer />}</Box>
			</XmProjectProvider>
		</Stack>
	);
};

import { pagesUri } from 'features/common/config/envConfig';
import { useCallback } from 'react';

export const useOpenSiteEditor = (): ((tenantName: string, siteName?: string) => void) => {
	return useCallback((tenantName: string, siteName?: string) => {
		const url = new URLSearchParams();
		let tenant = tenantName.replace('xmc-', '');
		tenant = tenant.replace('.sitecore-staging.cloud', '');
		if (siteName) {
			url.append('sc_site', siteName);
		}

		window.open(`${pagesUri}/composer/pages/editor?tenantName=${tenant}&${url.toString()}`);
	}, []);
};

import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { FC, useEffect } from 'react';
import { targetedEnvName as environment } from '../../common/config/envConfig';

export const AppSwitcher: FC = () => {
	const { getAccessTokenSilently: getAccessToken, loginWithRedirect, user } = useAuthWithClaims();

	// Init app switcher
	useEffect(() => {
		const initialize = () => {
			const appSwitcher = document.querySelector('app-switcher');
			if (!appSwitcher) return;

			void appSwitcher.componentOnReady().then(() => {
				void appSwitcher.init({
					getAccessToken,
					loginWithRedirect,
					environment,
					organizationId: user?.['https://auth.sitecorecloud.io/claims/org_id'] ?? '',
					tenantId: user?.['https://auth.sitecorecloud.io/claims/tenant_id'],
					isDevMode: false,
				});
			});
		};

		initialize();
	}, [loginWithRedirect, getAccessToken, user]);

	return (
		<div style={{ zIndex: 1 }}>
			<app-switcher></app-switcher>
		</div>
	);
};

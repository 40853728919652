import { AxiosResponse } from 'axios';
import { useAuthQuery } from 'features/common/apiUtils/queryHelper';
import config from 'features/common/config/config';
import _ from 'lodash';
import { useMemo } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { GitpodIntegrationWorkspace } from './models/gitpodIntegrationModel';

export type RefechCallbackAllWorkspaces = <TPageData>(
	options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<QueryObserverResult<AxiosResponse<GitpodIntegrationWorkspace[]>, unknown>>;

export const useGetWorkspaces = (): { workspacesList: GitpodIntegrationWorkspace[]; isLoading: boolean; refetch: RefechCallbackAllWorkspaces } => {
	const { data, isLoading, refetch } = useAuthQuery(['gitpodWorkspaces', 'getAll'], async (axiosInstance) => {
		const result = axiosInstance.get<GitpodIntegrationWorkspace[]>(config.renderingHost.get_gitpod_all_workspaces.url());

		return result;

		return {} as Promise<AxiosResponse<GitpodIntegrationWorkspace[]>>;
	});

	const list = useMemo(() => {
		return _.sortBy(data?.data ?? [], (workspace) => workspace.renderingHostPublicUrl.toLowerCase());
	}, [data?.data]);

	return { workspacesList: list, isLoading, refetch };
};

export type RefechCallbackWorkspace = <TPageData>(
	options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<QueryObserverResult<AxiosResponse<GitpodIntegrationWorkspace>, unknown>>;

export const useGetWorkspaceById = (ideId: string | undefined): { isLoading: boolean; refetch: RefechCallbackWorkspace } => {
	const { isLoading, refetch } = useAuthQuery(['workspaceById', ideId], async (axiosInstance) => {
		if (ideId) {
			const result = axiosInstance.get<GitpodIntegrationWorkspace>(config.renderingHost.get_gitpod_workspace_by_id.url(ideId));

			return result;
		}
		return {} as Promise<AxiosResponse<GitpodIntegrationWorkspace>>;
	});

	return { isLoading, refetch };
};

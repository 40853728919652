import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthWithClaims } from './useAuthWithClaims';

export type TenantAuthContextModel = {
	tenantId?: string;
	setTenantId: (newTenantId: string) => void;
	hasTenantAuth: boolean;
};

const defaultTenantAuthContextModel: TenantAuthContextModel = {
	setTenantId: () => {},
	hasTenantAuth: false,
};

export const TenantAuthContext = createContext<TenantAuthContextModel>(defaultTenantAuthContextModel);

export const TenantAuthProvider: FC = ({ children }) => {
	const [tenantId, setTenantId] = useState<string | undefined>();
	const [hasTenantAuth, setHasTenantAuth] = useState(false);
	const { getAccessTokenSilently } = useAuthWithClaims();

	useEffect(() => {
		if (!tenantId || hasTenantAuth) {
			return;
		}

		void getAccessTokenSilently({
			tenant_id: tenantId,
			ignoreCache: true,
		}).then(() => {
			setHasTenantAuth(true);
		});
	}, [tenantId, getAccessTokenSilently, hasTenantAuth]);

	const value: TenantAuthContextModel = useMemo(
		() => ({
			tenantId,
			setTenantId,
			hasTenantAuth,
		}),
		[tenantId, setTenantId, hasTenantAuth]
	);
	return <TenantAuthContext.Provider value={value}>{children}</TenantAuthContext.Provider>;
};

export const useTenantAuth = () => {
	return useContext(TenantAuthContext);
};

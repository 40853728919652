import { useGetTenant } from './tenants';
import { ITenant } from './tenantsModel';

export type GetTenantOrDefault = {
	tenant?: ITenant;
	isLoading: boolean;
};

export const useGetTenantOrDefault = (orgId: string, tenantName: string | undefined, enabled: boolean): GetTenantOrDefault => {
	const { data: namedTenant, isLoading: namedSearchLoading } = useGetTenant(
		{
			organizationId: orgId,
			tenantName: tenantName,
		},
		{
			enabled: enabled && !!tenantName,
		}
	);
	const { data: genericTenant, isLoading: genericSearchLoading } = useGetTenant(
		{
			organizationId: orgId,
		},
		{
			enabled: enabled,
		}
	);

	if (namedSearchLoading) {
		return { isLoading: true };
	}

	if (namedTenant) {
		return { tenant: namedTenant, isLoading: false };
	}

	if (genericSearchLoading) {
		return { isLoading: true };
	}

	if (genericTenant) {
		return { tenant: genericTenant, isLoading: false };
	}

	return { tenant: undefined, isLoading: false };
};

import { Stack, Box, HStack, Spacer, VStack, Image, Text, Button } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { sitecoreSizes } from 'features/common/styles/sizes';
import { FC, useEffect, useState } from 'react';
import { DashboardLoadingScreen } from 'features/common/components/DashboardLoadingScreen';
import { CreateRenderingHostButton } from '../components/CreateRenderingHostButton';
import { MillerContainer } from '../millerColumns/components/MillerContainer';
import Cactus from '../icons/illus-cactus.svg';
import { useGetRenderingHosts } from '../services/renderingHostManagmentService';
import { RenderingHost } from '../millerColumns/models/millerItemModel';

export const RenderingHostsLayout: FC = () => {
	const [refresh, setRefresh] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const { data, isLoading: loadingData, refetch } = useGetRenderingHosts();
	const [hosts, setHosts] = useState<RenderingHost[] | undefined>();
	const [needreload, setNeedReload] = useState(false);

	useEffect(() => {
		console.log('useEffect');

		setIsLoading(loadingData);
		setHosts(data.data);
		setNeedReload(false);
	}, [needreload, refetch, hosts, loadingData, data.data]);

	if (isLoading) {
		return (
			<Box marginTop={sitecoreSizes.size48}>
				<DashboardLoadingScreen text="Loading rendering hosts..." />
			</Box>
		);
	}

	if (!hosts || hosts.length === 0) {
		return (
			<VStack>
				<Box height={28} />
				<Image src={Cactus} fill="gray" height={20} />
				<Text color="blackAlpha.800" fontWeight="bold">
					<T _str="No Rendering Hosts created yet"></T>
				</Text>
				<Text color="blackAlpha.500">
					<T _str="Be the first to create one"></T>
				</Text>
				<CreateRenderingHostButton
					buttonProps={{ id: 'CreateRenderingHostButton', variant: 'primary', size: 'sm' }}
					refetchRenderingHostsList={() => refetch}
				>
					<T _str="Add rendering host" />
				</CreateRenderingHostButton>
			</VStack>
		);
	}

	return (
		<Stack direction="column" flexGrow={1} spacing="0" height="100%">
			<HStack paddingRight={7} paddingLeft={8} marginY={4} h={10}>
				<Spacer />
				<Button
					variant="primary"
					size="sm"
					margin="left"
					onClick={() => {
						setIsLoading(true);
						void refetch().then((result) => {
							setRefresh(refresh + 1);
							setHosts(result.data?.data);
							setIsLoading(false);
						});
					}}
				>
					<T _str="Refresh" />
				</Button>
				<CreateRenderingHostButton
					buttonProps={{ id: 'CreateRenderingHostButton', variant: 'primary', size: 'sm' }}
					refetchRenderingHostsList={() => {
						setIsLoading(true);
						void refetch().then((result) => {
							setRefresh(refresh + 1);
							setHosts(result.data?.data);
							setIsLoading(false);
						});
					}}
				>
					<T _str="Add rendering host" />
				</CreateRenderingHostButton>
			</HStack>
			<Box>
				<MillerContainer renderingHosts={hosts}></MillerContainer>
			</Box>
		</Stack>
	);
};

import { Box, CircularProgress, Text } from '@sitecore-ui/design-system';
import { T } from '@transifex/react';
import { FC } from 'react';
import { sitecoreColors } from '../styles/sizes';

type DashboardLoadingScreenProps = {
	bgColor?: string;
	text: string;
};

export const DashboardLoadingScreen: FC<DashboardLoadingScreenProps> = ({ bgColor, text }) => {
	return (
		<Box
			alignItems="center"
			display="flex"
			height="full"
			justifyContent="center"
			width="full"
			bgColor={bgColor || 'transparent'}
			data-testid="LoadingScreen"
		>
			<Box textAlign="center">
				<CircularProgress
					capIsRound={true}
					color={sitecoreColors.primary500}
					isIndeterminate={true}
					trackColor={sitecoreColors.blackAlpha200}
				/>
				<Text variant="regular" mt={6}>
					<T _str="{text}" text={text} />
				</Text>
			</Box>
		</Box>
	);
};

import { Box, Flex } from '@sitecore-ui/design-system';
import { FC, useRef } from 'react';
import { useGetDetailedItem } from '../hooks/useGetDetailedItem';
import { MillerContainerProps, RenderingHost } from '../models/millerItemModel';
import { DetailsSection } from './detailssection/DetailsSection';
import { MillerColumns, MillerControl } from './MillerColumns';
import { MillerItem } from './MillerItem';

export const MillerContainer: FC<MillerContainerProps> = (renderingHostProps) => {
	const { onItemSelected, currentItem, loading } = useGetDetailedItem();
	const columnControlRef = useRef<MillerControl<RenderingHost>>();

	return (
		<Box w="full" h="full" bgColor="gray.50">
			<Flex h="full">
				<Box h="full" flexGrow={1} minW="0">
					<MillerColumns
						column={renderingHostProps.renderingHosts}
						onClick={(item) => {
							onItemSelected(item);
						}}
						millerItemFactory={(props) => <MillerItem {...props} />}
						controlRef={columnControlRef}
					/>
				</Box>
				<Box flexShrink={0}>
					<DetailsSection currentItem={currentItem} loading={loading} />
				</Box>
			</Flex>
		</Box>
	);
};

import { Box, Flex, H4 } from '@sitecore-ui/design-system';
import { FC } from 'react';
import { DetailedItemBase } from '../../hooks/useGetDetailedItem';
import { ActionsPanel } from './ActionsPanel';
import { FieldDetails } from './FieldDetails';
import { Thumbnail } from './Thumbnail';

export const DetailsSection: FC<DetailedItemBase> = ({ currentItem, itemType, currentSite }) => {
	if (!currentItem) {
		return <></>;
	}
	return (
		<Flex direction="column" width="460px" borderLeft="1px" borderColor="gray.200" data-testid="detailsSection" h="full" overflowY="auto">
			<ActionsPanel currentItem={currentItem} itemType={itemType} currentSite={currentSite} />
			<H4 marginLeft="8" marginTop={2}>
				{currentItem.name}
			</H4>
			<Flex justifyContent="center" alignItems="center">
				<Thumbnail item={currentItem} siteAlternativeImageUrl="/bg-panel.png" type={itemType} />
			</Flex>
			<Box h="12"></Box>
			<Flex marginLeft="8" direction="column" gap="5">
				<FieldDetails name="ID:" value={currentItem.itemId} itemType={currentItem.type} />
				<FieldDetails name="name:" value={currentItem.name} itemType={currentItem.type} />
				<FieldDetails name="host url:" value={currentItem.hostUrl} itemType={currentItem.type} />
				<FieldDetails name="repository:" value={currentItem.repository} itemType={currentItem.type} />
				<FieldDetails name="repositoryBranch:" value={currentItem.repositoryBranch} itemType={currentItem.type} />
				<FieldDetails name="sourceControlIntegrationId:" value={currentItem.sourceControlIntegrationId} itemType={currentItem.type} />
				<FieldDetails name="repository:" value={currentItem.sourceControlIntegrationName} itemType={currentItem.type} />
			</Flex>
		</Flex>
	);
};

import {
	Container,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Input,
	Select,
	Stack,
	WarningTwoIcon,
} from '@sitecore-ui/design-system';
import { SingleValue } from '@sitecore-ui/design-system/lib/components/Select';
import { T } from '@transifex/react';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { CreateRenderingHostFormDataProps, DeployRenderingHostSelectItem } from '../data/createRenderingHostFormTypes';
import { useGetrenderingHostDeloymentOptions } from '../utils/utils';

export const findSelected = (selectedValue: boolean, items: DeployRenderingHostSelectItem[]): DeployRenderingHostSelectItem | undefined => {
	if (!items.length) {
		return undefined;
	}

	return items.find((i) => i.value === selectedValue);
};

export const CreateRenderingHostDeploymentStep: FC<FormikProps<CreateRenderingHostFormDataProps>> = (props) => {
	const renderingHostDeloymentOptions = useGetrenderingHostDeloymentOptions();

	return (
		<Container maxWidth="420px" padding={0}>
			<Stack direction="column" alignItems="center" spacing={7}>
				<FormControl isRequired={true} isInvalid={!!props.errors.formData?.renderingHostUrl}>
					<FormLabel htmlFor="RenderingHostName">
						<T _str="Rendering host name:" />
					</FormLabel>
					<Input
						type="url"
						name="formData.renderingHostName"
						id="RenderingHostName"
						value={props.values.formData.renderingHostName}
						onChange={props.handleChange}
						maxLength={50}
					/>
					<FormErrorMessage>
						<WarningTwoIcon />
						<T _str="Rendering host name can not be empty" />
					</FormErrorMessage>
					<FormHelperText>
						<T _str="Rendering host name" />
					</FormHelperText>
				</FormControl>
				<FormControl isRequired={true} isInvalid={false}>
					<FormLabel htmlFor="RenderingHostUrl">
						<T _str="Rendering host to use:" />
					</FormLabel>
					<Select
						id="formData.useGitpodRenderingHost"
						options={renderingHostDeloymentOptions}
						value={findSelected(props.values.formData.useGitpodRenderingHost, renderingHostDeloymentOptions)}
						onChange={(newValue: SingleValue<DeployRenderingHostSelectItem>) => {
							props.setFieldValue('formData.useGitpodRenderingHost', (newValue as DeployRenderingHostSelectItem).value);
						}}
					/>
					<FormErrorMessage>
						<WarningTwoIcon />
					</FormErrorMessage>
				</FormControl>
				<FormControl
					isDisabled={props.values.formData.useGitpodRenderingHost}
					isRequired={!props.values.formData.useGitpodRenderingHost}
					isInvalid={!!props.errors.formData?.renderingHostUrl}
				>
					<FormLabel htmlFor="RenderingHostUrl">
						<T _str="Rendering host url:" />
					</FormLabel>
					<Input
						type="url"
						name="formData.renderingHostUrl"
						id="renderingHostUrl"
						value={props.values.formData.renderingHostUrl}
						onChange={props.handleChange}
						maxLength={300}
					/>
					<FormErrorMessage>
						<WarningTwoIcon />
						<T _str="Rendering host url can not be empty" />
					</FormErrorMessage>
					<FormHelperText>
						<T _str="Url to deployed Rendering host" />
					</FormHelperText>
				</FormControl>
			</Stack>
		</Container>
	);
};
